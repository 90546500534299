import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService } from 'src/app/client/client.service';
import { GlobalsService } from 'src/app/globals.service';

@Component({
    selector: 'app-file-reader',
    templateUrl: './combo-file-reader.component.html',
    styleUrls: ['./combo-file-reader.component.scss']
})
export class ComboFileReaderComponent implements OnInit {

    private fileUpload1: any;
    fileCont: any = null;
    fileName = '';

    constructor(
        public dialogRef: MatDialogRef<ComboFileReaderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private client: ClientService,
        public globals: GlobalsService
    ) { }

    ngOnInit() {
        this.fileUpload1 = document.getElementById('fileUpload1') as HTMLInputElement;
    }

    onFileButtonClick() {
        this.fileUpload1 = document.getElementById('fileUpload1') as HTMLInputElement;
        this.fileUpload1.click();
    }

    fileChange() {
        const file = this.fileUpload1.files[0];
        const imagefiletype = file.type;
        const imageSize = file.size;
        this.fileName = file.name;
        if (imageSize > 2 * 1024 * 1024) {
            this.globals.showDialog(this.globals.loc.gl_error, 'Too big Image File. At most 1Mb allowed');
            return false;
        }
        const match = ['application/json'];
        if ((imagefiletype !== match[0])) {
            this.globals.showDialog(this.globals.loc.gl_error, 'Please Select A valid JSON file.');
            return false;
        } else {
            const reader = new FileReader();
            reader.onload = ((e) => {
                this.fileCont = (e.target as any).result as string;
            });
            reader.readAsText(file);
        }
    }

    onCloseClick(btnOk: boolean) {
        if (btnOk) {
            this.dialogRef.close(this.fileCont);
        } else {
            this.dialogRef.close(null);
        }
    }

}

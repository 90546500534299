import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { HomeComponent } from "./pages/home/home.component";
import { AuthService } from "./auth/auth-service.service";
import { ChatComponent } from "./pages/chat/chat.component";
import { BotChatComponent } from "./pages/bot-chat/bot-chat.component";

const routes: Routes = [
    { path: "", redirectTo: "/chat", pathMatch: "full" },
    {
        path: "admin",
        loadChildren: () =>
            import("./pages/admin/admin.module").then((mod) => mod.AdminModule),
    },
    {
        path: "algorithm",
        loadChildren: () =>
            import("./pages/algorithm/algorithm.module").then(
                (mod) => mod.AlgorithmModule
            ),
    },
    {
        path: "domain",
        loadChildren: () =>
            import("./pages/domain/domain.module").then(
                (mod) => mod.DomainModule
            ),
    },
    {
        path: "entity",
        loadChildren: () =>
            import("./pages/entity/entity.module").then(
                (mod) => mod.EntityModule
            ),
    },
    {
        path: "intent",
        loadChildren: () =>
            import("./pages/intent/intent.module").then(
                (mod) => mod.IntentModule
            ),
    },
    {
        path: "chat",
        component: ChatComponent,
        canActivate: [AuthService],
        data: { role: ["ADMIN", "DOMAIN_EXPERT", "USER", "ANONYMOUS"] },
    },
    {
        path: "bot",
        component: BotChatComponent,
        canActivate: [AuthService],
        data: { role: ["ADMIN", "DOMAIN_EXPERT", "USER", "ANONYMOUS"] },
    },
    {
        path: "home",
        component: HomeComponent,
        canActivate: [AuthService],
        data: { role: ["ADMIN", "DOMAIN_EXPERT"] },
    },
    {
        path: "sentence",
        loadChildren: () =>
            import("./pages/sentences/sentences.module").then(
                (mod) => mod.SentencesModule
            ),
    },
    {
        path: "register",
        loadChildren: () =>
            import("./pages/register/register.module").then(
                (mod) => mod.RegisterModule
            ),
    },
    {
        path: "forgot-password",
        loadChildren: () =>
            import("./pages/forgot-password/forgot-password.module").then(
                (mod) => mod.ForgotPasswordModule
            ),
    },
    {
        path: "reset-password",
        loadChildren: () =>
            import("./pages/reset-password/reset-password.module").then(
                (mod) => mod.ResetPasswordModule
            ),
    },
    {
        path: "dataset",
        loadChildren: () =>
            import("./pages/dataset/dataset.module").then(
                (mod) => mod.DatasetModule
            ),
    },
    {
        path: "dataset-import",
        loadChildren: () =>
            import("./pages/dataset/dataset-import/dataset-import.module").then(
                (mod) => mod.DatasetImportModule
            ),
    },
    {
        path: "user-history",
        loadChildren: () =>
            import("./pages/user-history/user-history.module").then(
                (mod) => mod.UserHistoryModule
            ),
    },
    {
        path: "ner-ie",
        loadChildren: () =>
            import("./pages/ner-ie/ner-ie.module").then(
                (mod) => mod.NerIeModule
            ),
    },
    {
        path: "retrain",
        loadChildren: () =>
            import("./pages/retraining/retraining.module").then(
                (mod) => mod.RetrainingModule
            ),
    },
    {
        path: "performance",
        loadChildren: () =>
            import("./pages/performance/performance.module").then(
                (mod) => mod.PerformanceModule
            ),
    },
    {
        path: "statistics",
        loadChildren: () =>
            import("./pages/statistics/statistics.module").then(
                (mod) => mod.StatisticsModule
            ),
    },
    {
        path: "chat-config",
        loadChildren: () =>
            import("./pages/chat-config/chat-config.module").then(
                (mod) => mod.ChatConfigModule
            ),
    },
    { path: "**", component: NotFoundComponent },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })], // <-- debugging purposes only)
    exports: [RouterModule]
})
export class AppRoutingModule { }

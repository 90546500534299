import { Component, Input, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { GlobalsService } from '../../globals.service';

@Component({
    selector: 'app-password-strength',
    templateUrl: './password-strength.component.html',
    styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnChanges {
    @Input() public passwordToCheck: string;
    @Output() passwordStrength = new EventEmitter<boolean>();
    bar0: string;
    bar1: string;
    bar2: string;
    bar3: string;
    bar4: string;
    msg = '';
    private colors = ['#DDD', 'darkred', 'orangered', 'orange', 'yellowgreen', 'green'];

    constructor(
        private globals: GlobalsService
    ) {
        // changed language
    }

    private static checkStrength(p) {
        // for debug let p = "aA1bB1!!";
        const lowerLetters = (p.match(/[a-z]/g) || []).length;
        const upperLetters = (p.match(/[A-Z]/g) || []).length;
        // more generic => const letters = (p.match(/[a-z]|[A-Z]/g) || []).length;
        const numbers = (p.match(/[0-9]/g) || []).length;
        const symbols = (p.match(/[~!@#\$%\^&\*_\-\+=`\|\\\(\){}\[\]:;"'<>,\.\?]/g) || []).length;
        const flags = [lowerLetters, upperLetters, numbers, symbols];
        let passedMatches = 0;
        for (const flag of flags) {
            passedMatches += flag > 0 ? 1 : 0;
        }
        passedMatches += p.length >= 6 ? 1 : 0;
        return passedMatches * 10;
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
        const password = changes.passwordToCheck.currentValue;
        this.setBarColors(5, '#DDD');
        if (password) {
            const c = this.getColor(PasswordStrengthComponent.checkStrength(password));
            this.setBarColors(c.idx, c.col);
            const pwdStrength = PasswordStrengthComponent.checkStrength(password);
            pwdStrength === 50 ? this.passwordStrength.emit(true) : this.passwordStrength.emit(false);
            switch (c.idx) {
                case 1:
                    this.msg = this.globals.loc.gl_str_poor + this.globals.loc.gl_str_rule;
                    break;
                case 2:
                    this.msg = this.globals.loc.gl_str_notgood + this.globals.loc.gl_str_rule;
                    break;
                case 3:
                case 4:
                    this.msg = this.globals.loc.gl_str_average + this.globals.loc.gl_str_rule;
                    break;
                case 5:
                    this.msg = this.globals.loc.gl_str_good;
                    break;
            }
        } else {
            this.msg = '';
        }
    }

    private getColor(s) {
        const idx = s / 10;
        return {
            idx: idx,
            col: this.colors[idx]
        };
    }

    private setBarColors(count, col) {
        for (let n = 0; n < count; n++) {
            this['bar' + n] = col;
        }
    }
}

import { NgModule } from '@angular/core';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatModule } from '../mat.module';
import { CheckListComponent } from './check-list/check-list.component';
import { RateComponent } from './rating/rate.component';

@NgModule({
  declarations: [FileuploadComponent, ListFilterComponent, PasswordStrengthComponent, CheckListComponent, RateComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatModule],
  exports: [FileuploadComponent, ListFilterComponent, PasswordStrengthComponent, CheckListComponent, RateComponent]
})
export class SharedCompModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SimpleDialogComponent } from './dialogs/simple/simple.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BaseListComponent } from './base/base-list/base-list.component';
import { BaseEntityComponent } from './base/base-entity/base-entity.component';
import { MatModule } from './mat.module';
import { BaseComponent } from './base/base/base.component';
import { HomeComponent } from './pages/home/home.component';
import { ComboFileReaderComponent } from './dialogs/combo-file-reader/combo-file-reader.component';
import { UNDER_TEST } from './helper-classes/helper';
import { ChatComponent } from './pages/chat/chat.component';
import { BotChatComponent } from './pages/bot-chat/bot-chat.component';
import { SharedCompModule } from './components/shared-comp.module';

@NgModule({
    declarations: [
        AppComponent, NotFoundComponent, SimpleDialogComponent, ChatComponent, BotChatComponent,
        ComboFileReaderComponent, BaseComponent, BaseListComponent, BaseEntityComponent, HomeComponent],
    imports: [
        BrowserModule, AppRoutingModule, BrowserAnimationsModule, HttpClientModule, MomentModule,
        ReactiveFormsModule, FormsModule, MatModule, SharedCompModule
    ],
    providers: [
        { provide: UNDER_TEST, useValue: { test: false, blockData: false } },
    ],
    // entryComponents: [
    //     SimpleDialogComponent, ComboDialogComponent, ComboFileReaderComponent
    // ],
    bootstrap: [AppComponent]
})
export class AppModule { }

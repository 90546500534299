import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { GlobalsService } from '../../globals.service';
import { ClientService } from '../../client/client.service';
import { AuthService } from '../../auth/auth-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx/xlsx.mini';
import { flatten } from 'flat';
import { Subject, Subscription } from 'rxjs';
import { Location as LocationService } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { TestEnv, UNDER_TEST } from 'src/app/helper-classes/helper';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-base-list',
    template: 'NO-TEMPLATE'
})
export class BaseListComponent implements OnInit, OnDestroy, AfterViewInit {

    public displayedColumns: Array<string> = [];
    public displayedColumnProps: Array<Array<any>> = [[], [], []];
    public displayedColumnLookupInfo: Array<any> = [];
    public zoomedRow = null;
    public highlightedRow = null;
    public allowMultiSelect = false;
    public selection = new SelectionModel<any>(true, []);
    public canEdit = false;
    public canDeleteRow = false;
    public canCreate = false;
    public canGoBack = false;
    public exportFileName: string;
    public listId: string;
    public filterValue: string;
    protected listData: Array<any> = [];
    public paramId: string;
    protected unsub$ = new SubSink();
    protected routerSubscription: Subscription;
    protected table: MatTable<any>;
    protected paginator: MatPaginator;
    protected sort: MatSort;
    // in case of a mat-table in a "ng-If" that is hiding the mat-table, the paginator and sort are null. Must be fixed. Possible solution is to use setters
    @ViewChild('TABLE', { static: false }) set _table(tbl: MatTable<any>) {
        if (tbl) { // initially setter gets called with undefined
            this.table = tbl;
        }
    };
    @ViewChild(MatPaginator, { static: false }) set _paginator(pg: MatPaginator) {
        if (pg) {
            this.paginator = pg;
        }
    };
    @ViewChild(MatSort, { static: false }) set _sort(srt: MatSort) {
        if (srt) {
            this.sort = srt;
        }
    };
    // @ViewChild(MatTable) table: MatTable<any>;
    // this.table.renderRows();
    public dataSource: MatTableDataSource<any[]>;
    public FILTER_PLACEHOLDER = 'Filter';
    public FILTER_INITIAL_VALUE = '';
    protected justInited = true;
    protected underTest: TestEnv;

    constructor(
        protected injector: Injector,
        protected routeParams: ActivatedRoute,
        public globals: GlobalsService,
        public auth: AuthService,
        public client: ClientService,
        protected router: Router,
        protected location: LocationService
    ) {

    }

    ngOnInit() {
        this.underTest = this.injector.get<TestEnv>(UNDER_TEST);
        this.routerSubscription = this.routeParams.paramMap.subscribe(params => {
            this.paramId = params.get('id');
            this.globals.setMenuVisible(true);
            this.client.menuChanged();
            // console.log(JSON.stringify(this.underTest));
            // console.log(JSON.stringify(this.client.currentUser));
            if (!this.underTest.blockData) {
                this.loadData();
            }
        });
        this.unsub$.sink = this.globals.getCurrentLangObservable()
            .subscribe(lng => {
                this.onLangChange();
            });
    }

    ngAfterViewInit() {
        // do something before draw screen
    }

    ngOnDestroy() {
        this.globals.setSpinnerVisible(false);
        this.unsub$.unsubscribe();
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    protected loadData() {
        // intentionaly empty
    }

    protected afterLoadData() {
        // setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.listData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'code': return (item as any).code;
                case 'insert_on': return new Date((item as any).insert_on);
                case 'update_on': return new Date((item as any).insert_on);
                case 'next_survey': return new Date((item as any).next_survey);
                case 'uploadDate_disp': return new Date((item as any).uploadDate);
                case 'measureDate_disp': return new Date((item as any).measureDate);
                default: return item[property];
            }
        };
        const _filterValue = localStorage.getItem(this.exportFileName);
        if (_filterValue) {
            this.applyFilter(_filterValue);
        }
        // }, 0);
        // this.dataSource.filterPredicate =
        // (data:  any, filterValue: string) => {
        //     if (data)
        //     data.name.trim().toLowerCase().indexOf(filterValue) !== -1;
        //     return true;
        // }
    }

    protected onLangChange() {
        this.FILTER_PLACEHOLDER = this.globals.loc.gl_filter
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    public export(): void {
        // converts a DOM TABLE element to a worksheet // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
        const flatten_res = [];
        this.dataSource.filteredData.forEach(item => {
            flatten_res.push(flatten<any, any[]>(item));
        });
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flatten_res);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, this.exportFileName);
    }

    public applyFilter(filterValue: string) {
        this.filterValue = filterValue;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    public goBack() {
        this.location.back();
    }

    public zoomEntity(row: any): void {
        // intentionaly left empty
    }

    public deleteEntity(row: any): void {
        // intentionaly left empty
    }

}

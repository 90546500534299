export const EN = {
    dateFormat: "YYYY-MM-DD",
    gl_required: "Required",
    gl_submit: "Submit",
    gl_error: "Error!",
    gl_warning: "Warning!",
    gl_information: "Information!",
    gl_confirmation: "Confirmation",
    gl_yes: "Yes",
    gl_no: "No",
    gl_cancel: "Cancel",
    gl_cancel_exit: "Cancel & Exit",
    gl_cancel_confirm: "Cancel latest changes. Are you sure?",
    gl_show: "Show",
    gl_ok: "Ok",
    gl_done: "Done",
    gl_exit: "Exit",
    gl_apply: "Apply",
    gl_save: "Save",
    gl_saveStay: "Save & Stay",
    gl_save_exit: "Save & Exit",
    gl_is: "is",
    gl_delete: "Delete",
    gl_new: "New",
    gl_edit: "Edit",
    gl_reset: "RESET",
    gl_post: "POST",
    gl_manage: "Manage Entities",
    gl_export_all: "Export All Data",
    gl_export_selected: "Export Selection Only",
    gl_total_records: "Total records",
    gl_rows_selected: "Rows selected",
    gl_error_permisions: "Your role has not permission to perform this action",
    gl_str_poor: "Strength: Poor",
    gl_str_notgood: "Strength: Not good",
    gl_str_average: "Strength: Average",
    gl_str_good: "Strength: Good",
    gl_str_rule:
        ". Required characters (at least): 1 Upper case, 1 Lower case, 1 Number, 1 Punctuation mark, 6 char length",
    gl_export: "Export as CSV",
    gl_delete_confirm: "Delete the Record? Please confirm.",
    gl_delete_confirm_multi: "Delete multiple Records? Please confirm.",
    gl_deleted_ok: "The record has been  deleted successfully",
    gl_discard_changes: "There are pending changes. Discard these changes?",
    gl_exit_confirm: "Exiting. Are you sure?",
    gl_saved_ok: "The record has been saved successfully",
    gl_filter: "Filter",
    gl_already_exist:
        'Relations exist for this Intent and Domain. Please use "Manage Relations" function to update these relations.',
    gl_copied_to_clipboard: "The link is copied to the clipboard.",

    app_title: "Bell Boy Bot",
    app_title1: "Chatbot for tourism",
    app_cookies1: "The web site",
    app_cookies2: `uses cookies for better navigation experience. To learn more please read the`,
    app_cookies3: "usage terms and conditions.",
    app_cookies_btn: "I agree",

    app_footer1: "Usage terms",
    app_footer2: "Site map",
    app_footer3: "15124, Marousi, Athens Greece - All Rights Reserved.",

    menu_entities: "Entities",
    menu_policies: "Policies",
    menu_settings: "Settings",
    menu_current_user: "User: ",
    menu_current_role: "Role: ",
    menu_login: "Login",
    menu_register: "Register",
    menu_change_password: "Change password",
    menu_logout: "Logout",
    menu_enter_text: "Enter text here",
    menu_send_button: "SEND",
    menu_bot_text1: "Hello. I’m BBB.",
    menu_bot_text2: `Type something like “Suggest a hotel in athens”`,

    login_card_header: "User login",
    admin_login_card_header: "Admin login",
    login_email: "User email",
    login_password: "Password",
    login_forgot_password: "Forgot your password?",
    login_submit: "Submit",
    login_error_email: "Please enter a valid Email",
    login_error_password: "Please enter a valid password",
    login_error_invalid_credentials: "Login failed. ",
    logout_confirm: "Logging out. Please confirm",
    forgot_password: "Forgot Password?",
    forgot_password_text:
        " Please enter your email, and we'll send you instructions of how to reset your password. Make sure that you type your email correctly, and it's the same one that you used to signup. ",

    reset_password_title: "Reset Password",
    reset_password_new: "New password",
    reset_password_retype: "Confirm password",
    reset_password_error: "Error",
    reset_password_error_body: "No user exists with provided email",
    reset_password_success: "Success!",
    reset_password_success_body:
        "An email message has been sent containing a link to reset the password",
    reset_password_invalid_uuid: "Invalid uuid is provided",
    reset_password_expired_uuid: "The link has expired",
    reset_password_successful_title: "Password Changed!",
    reset_password_successful_body:
        "Your password has been changed successfully",
    reset_password_not_valid:
        "Your password and confirmation password must match",
    notfound_header: "Page not found!",

    password_change_card_header: "Change password",
    password_reset_card_header: "Reset password",
    password_old: "Old password",
    password_new: "New password",
    password_repeat: "Retype password",
    password_error_old: "Please enter your old password",
    password_error_new: "Please enter your new password (6 characters minimum)",
    password_error_repeat:
        "Please retype your new password (6 characters minimum)",
    password_error_not_same:
        "The re-typed password must be the same with the new password!",
    password_directions_sent:
        "Directions  have been sent to your email account. Please follow these directions to reset your password.",
    password_relogin:
        "Your password has been changed. Please login again with the new password.",
    password_link_invalid:
        'The link is invalid or expired. You must repeat the "forgot password" procedure, in order to get a fresh link!',
    password_change_failed:
        "The password DID NOT change! Please retry with the correct current password!",

    account_activate_card_header: "Activate account",
    account_activate_link_invalid:
        "The account activation link is invalid or expired. You must contact the administrator, in order to get a fresh link!",
    account_activate_login:
        "Your account has been activated. You can now login with your credentials.",

    // USER
    user_list_caption: "Users list",
    user_list_new_user: "New user",
    user_list_field_captions: [
        "Email",
        "Νame",
        "Role",
        "Active",
        "Birth Year",
        "Gender",
        "Language",
        "Topics",
        "Created at",
    ],

    user_ent_caption: "User",
    user_ent_name: "Name",
    user_ent_email: "Email",
    user_ent_role: "Role",
    user_ent_status: "Status",
    user_ent_created: "Created at",
    user_ent_password: "Password",
    user_ent_set_password: "Set new password",
    user_ent_set_password_error: "Password is required for new user",
    user_ent_sex: "Gender",
    user_ent_language: "Language",
    user_ent_dob: "Birth year",
    user_ent_dob_error: "Birth year must be in the range 1920-2050",
    user_ent_topics: "Topics",

    user_history_list_field_captions: [
        "UserId",
        "Date",
        "Domain",
        "Domain Id",
        "Intent",
        "Intent Id",
        "Predicted Entities",
        "Predicted Values",
        "Text",
        "Answer",
    ],
    user_history_caption: "User History",
    user_history_search_btn: "Search",
    user_history_answer: "Answer Content",

    // Algorithm
    algorithm_list_caption: "Algorithms list",
    algorithm_list_new_algorithm: "New algorithm",
    algorithm_list_field_captions: [
        "Νame",
        "Description",
        "Created at",
        "CustomId",
    ],

    algorithm_ent_caption: "Algorithm",
    algorithm_ent_name: "Name",
    algorithm_ent_descr: "Description",
    algorithm_ent_created: "Created at",

    // Domain
    domain_list_caption: "Domains list",
    domain_list_new_domain: "New domain",
    domain_list_field_captions: [
        "Νame",
        "Description",
        "Visible",
        "Created at",
        "Custom id",
    ],

    domain_ent_caption: "Domain",
    domain_ent_name: "Name",
    domain_ent_descr: "Description",
    domain_ent_created: "Created at",
    domain_ent_customId: "Custom ID",

    //Sentence Labelling
    sentence_dataset: "Dataset Name",
    sentence_dataset_descr: "Dataset Description",
    sentence_title: "Sentence Labeling",
    sentence_domain: "Domain",
    sentence_load_int_ent: "Load Intents & Entities",
    sentence_add: "Add Sentence",
    sentence_save: "Save Labelling",
    sentence_warning:
        "By unlocking the sentence you are going to lose all the relations you have created. Are you sure you want to unlock the sentence?",
    sentence_entity: "Entity",
    sentence_sentec: "Sentence",
    sentence_use: "Use Sentence",
    sentence_fileload: "File Load",
    sentence_upload_title: "Upload a text file (example.txt) with sentences.",
    sentence_choose_file: "Choose File",

    //Datasets
    dataset_list_field_captions: [
        "Id",
        "Name",
        "Domain Name",
        "User",
        "Lang",
        "Date time",
        "Algorithm",
        "Link to open the 3 files",
        "Master",
        "Action",
    ],
    dataset_list_caption: "Dataset list",
    dataset_corpus_file: "Corpus File Content",
    dataset_entities_file: "Entities File Content",
    dataset_intents_file: "Intents File Content",
    dataset_merge_popupTxt: "You want to merge this dataset? Please Confirm",
    dataset_delete_popupTxt: "You want to delete this dataset? Please Confirm",
    dataset_merge: "Merge",
    dataset_name: "Dataset name",
    dataset_desc: "Dataset Description",
    datasets_import: "Import",
    datasets_submit: "Save Dataset",
    datasets_info: "Info",
    datasets_msg: "The import have been successful",
    datasets_οκ: "ΟΚ",

    // Entity
    entity_list_caption: "Entities list",
    entity_list_new_entity: "New entity",
    entity_list_field_captions: ["Νame", "Created at"],
    entity_field_task: "Task",

    entity_ent_caption: "Entity",
    entity_ent_name: "Name",
    entity_ent_created: "Created at",
    entity_ent_question_en: "Question(English)",
    entity_ent_question_gr: "Question(Greek)",

    results_caption: "Results: ",

    // Intent
    intent_list_caption: "Intent list",
    intent_list_new_intent: "New intent",
    intent_rel_caption: "Intent Relations",
    intent_list_field_captions: ["Νame", "Created at"],
    intent_rel_field_captions: ["Intent", "Domain", "Entities", "Actions"],
    intent_relation_new_relation: ["New Relation"],
    intent_continue_rel: "Continue to relations",

    intent_tab_gen_caption: "Generic",
    intent_tab_rel_caption: "Relations",
    intent_ent_caption: "Intent",
    intent_ent_name: "Name",
    intent_ent_created: "Created at",
    intent_en: "Answer template English",
    intent_el: "Answer template Greek",
    intent_example: "Example answer: The weighting coefficient for course",

    intent_action_1: "Add Primary",
    intent_action_2: "Add Optional",
    intent_action_3: "Remove",

    propertyName_caption: "Property",
    questions_caption: " Questions",
    question_caption: "Question",
    answer_caption: "Answer",
    answer_code: "Answer code",
    question_remove_question_block: "Remove Question block",
    question_remove_sub_question_block: "Remove Sub-Question block",
    rate_prompt: 'Please rate our bot, based on your overall experience.',
    rate_thanks: 'Thank you for the feedback.',

    // ChatBot Config
    chatbot_config_title: "Chatbot Config",

    //performance
    performance_title: "Performance Result",
    performance_list_field_captions: [
        "ID",
        "Datasets",
        "Algorithm Name",
        "Domain Name",
        "Creation Date",
        "Language",
        "Status",
        "Actions",
    ],
    performance_list_field_results: [
        "Type",
        "Name",
        "Old Accuracy ",
        "Accuracy",
        "Old Precision",
        "Precision",
        "Old f1",
        "f1",
    ],
    performance_list_action: "View",
    performance_results: "Training results of: ",
    performance_results_algorithm: "Algorithm",
    performance_results_lang: "Language",
    performance_results_domain: "Domain",
    performance_results_status: "Status",
    performance_results_intentF1Avg: "Intent F1 Average",
    performance_results_intentPrecisionAvg: "Intent Precision Average",
    performance_results_intentRecallAvg: "Intent Recall Average",
    performance_results_entitiesF1Avg: "Entities F1 Average",
    performance_results_entitiesPrecisionAvg: "Entities Precision Average",
    performance_results_entitiesRecallAvg: "Entities Recall Average",
    performance_results_intentF1AvgOld: "Old Intent F1 Average",
    performance_results_intentPrecisionAvgOld: "Old Intent Precision Average",
    performance_results_intentRecallAvgOld: "Old Intent Recall Average",
    performance_results_entitiesF1AvgOld: "Old Entities F1 Average",
    performance_results_entitiesPrecisionAvgOld:
        "Old Entities Precision Average",
    performance_results_entitiesRecallAvgOld: "Old Entities Recall Average",
    performance_results_cancel: "Cancel",
    performance_results_discard: "Discard",
    performance_results_save: "Save",
    performance_results_dicard_message: "Are you sure you want to discard?",

    //retrain
    retraing_title: "Retrain",
    retraing_lan: "Language",
    retraing_dom: "Domain",
    retraing_alg: "Algorithm",
    retraing_list_field_captions: ["#", "Name", "User", "Files"],
    retraing_action_1: " Load Datasets",
    retraing_action_2: "Train Model",
    retraing_msg406: "Based on the above information, a process has already been found that is in the developing phase. You must first save it or discard it.",

    //statictis
    statistics_title: "Analytics",
    statictis_enter_date: "Enter a date range",
    statictis_type: "Type",
    statictis_results: "Results",

    // =============  Enum ================
    enum_genders: [
        { id: "NA", name: "NA" },
        { id: "MALE", name: "Male" },
        { id: "FEMALE", name: "Female" },
    ],
    enum_salutations: ["Mr", "Ms"],
    enum_rolesArray: [
        { id: "ADMIN", name: "Administrator" },
        { id: "DOMAIN_EXPERT", name: "Domain Expert" },
        { id: "USER", name: "User" },
        { id: "ANONYMOUS", name: "Anonymous" },
    ],
    enum_user_statusArray: ["ACTIVE", "DISABLED"],
    enum_MENU: [
        {
            id: 101,
            caption: "Home",
            visible: false,
            icon: "home",
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                //{ id: 21, caption: 'Machinery condition', visible: false, icon: 'home_repair_service', roles: ['ADMIN', 'DOMAIN_EXPERT', 'USER'] },
                //{ id: 22, caption: 'Last survey', visible: false, icon: '', roles: ['ADMIN', 'DOMAIN_EXPERT',  'USER'] }
            ],
        },
        {
            id: 100,
            caption: "Chatbot",
            visible: false,
            icon: "question_answer",
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER", "ANONYMOUS"],
            sub: [
                //{ id: 100, caption: 'List users', visible: false, icon: 'people_ald', roles: ['ADMIN'] },
            ],
        },
        {
            id: 230,
            caption: "NER & IE",
            visible: false,
            icon: "colorize",
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                // { id: 110, caption: 'List Companies', visible: false, icon: 'waves', roles: ['ADMIN'] }
            ],
        },
        {
            id: 210,
            caption: "Sentence Labeling",
            icon: "label",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                //{ id: 120, caption: 'List Ships', visible: false, icon: 'directions_boat', roles: ['ADMIN'] }
            ],
        },
        {
            id: 220,
            caption: "Datasets",
            icon: "dns",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                //{ id: 130, caption: 'List Systems', visible: false, icon: 'home_repair_service', roles: ['ADMIN'] }
            ],
        },
        {
            id: -1,
            caption: "Model",
            icon: "batch_prediction",
            visible: false,
            roles: ["ADMIN"],
            sub: [
                {
                    id: 240,
                    caption: "Retraining",
                    icon: "model_training",
                    visible: false,
                    roles: ["ADMIN"],
                },
                {
                    id: 250,
                    caption: "Performance Results",
                    visible: false,
                    icon: "app_registration",
                    roles: ["ADMIN"],
                },
            ],
        },
        {
            id: -1,
            caption: "Setup",
            icon: "settings_applications",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                {
                    id: 110,
                    caption: "Users",
                    visible: false,
                    icon: "display_settings",
                    roles: ["ADMIN"],
                },
                {
                    id: 120,
                    caption: "M.L. Algorithms",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN"],
                },
                {
                    id: 130,
                    caption: "Domains",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN", "DOMAIN_EXPERT"],
                },
                {
                    id: 150,
                    caption: "Entities",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN"],
                },
                {
                    id: 160,
                    caption: "Intents",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN", "DOMAIN_EXPERT"],
                },
                {
                    id: 190,
                    caption: "Intent Relations",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN", "DOMAIN_EXPERT"],
                },
            ],
        },
        {
            id: 280,
            caption: "Chatbot Config",
            icon: "live_help",
            visible: false,
            roles: ["ADMIN"],
            sub: [],
        },
        {
            id: 260,
            caption: "Profile",
            icon: "person",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER"],
            sub: [],
        },
        {
            id: 270,
            caption: "User History",
            icon: "dns",
            visible: false,
            roles: ["ADMIN"],
            sub: [],
        },
        {
            id: 290,
            caption: "Analytics",
            icon: "show_chart",
            visible: false,
            roles: ["ADMIN"],
            sub: [],
        },
        {
            id: 200,
            caption: "Logout",
            icon: "logout",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER"],
            sub: [],
        },
        {
            id: 300,
            caption: "About",
            visible: false,
            icon: "info",
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER", "ANONYMOUS"],
            sub: [],
        },
    ],
};

import { InjectionToken } from '@angular/core';

export class User {
    id: string;
    title: string;
    gender: string;
    identificationNr: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    telephone: string;
    fax: string;
    clearenceLevel: string;
    rank: string;
    maxInvitPerCourse: string;
    role: string;
    nationality: {
        id: null,
        name: null
    };
    country: {
        id: null,
        name: null
    };
    active: boolean;
    altEmail1: string;
    altEmail2: string;
    altTelephone1: string;
    dateOfBirth: Date;
    identificationExpiration: Date;
    identificationType: string;
    // Enum:[ Passport, IDCard ]
    professionalEmail: string;
    roleObj: any;
    telephone2: string;
    workExperience: {
        description: null,
        id: null
    };
    workField: {
        id: null, name: null
    };
    institution: string;
}
export class Country {
    id: string;
    name: string;
}

export class Course {
    id: string;
    fromDate: Date;
    toDate: Date;
    place: string;
    title: string;
    curricula: {
        id: string;
        title: string;
    } | string;
    status: string;
    statusObj: {
        id: string,
        name: string
    };
    category: {
        id: string,
        initial: string,
        name: string
    };
    files: [];
    fileDisp: string;
    confirmationsDeadline: Date;
    nominationsDeadline: Date;
    country: Country;
    createdBy: any;
    createdByFull: any;
    institution: any;
    certificatesGenerated: boolean;
    days: any;
}

export class Category {
    id: string;
    initial: string;
    name: string;
}

export class Curricula {
    id: string;
    title: string;
}

export class Institution {
    city: string;
    country: Country;
    countryName: string;
    id: string;
    membershipStatus: string; // Associated_Network_Partner, Member
    name: string;
}

export class Participation {
    certificatePath: string;
    confirmedBy: User;
    confirmedByDisp: string;
    course: Course;
    courseDisp: string;
    id: string;
    nominator: User;
    nominatorFullName: string;
    participationCancelled: boolean;
    priority: number;
    publishPhotos: boolean;
    status: string; // [ PENDING, ACCEPTED, REJECTED, PARTICIPATED ]
    statusDisp: string;
    user: User;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
}

export interface ResponseStruct {
    errorCode: string;
    errorMessage: string;
    data: any;
}
export interface Ichat {
    id: any;
    sender: any;
    text: string | Array<string> | object;
    icon: any;
    button: any;
    rate: any;
}
export interface TestEnv { test: boolean; blockData: boolean; }
export const UNDER_TEST = new InjectionToken<TestEnv>('underTest');

// Sofia GET Intents and Entities for domain
let y = [
    {
        id: 1, name: 'sadasdfadg', entities: [
            { id: 1, name: 'a_intent_b' },
            { id: 1, name: 'a_intent_b' },
            { id: 1, name: 'a_intent_b' },
        ]
    },
    {
        id: 1, name: 'sadasdfadg', entities: [
            { id: 1, name: 'a_intent_b' },
            { id: 1, name: 'a_intent_b' },
            { id: 1, name: 'a_intent_b' },
        ]
    }
]

import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { Observable } from 'rxjs';
import { ClientService } from 'src/app/client/client.service';

@Component({
  selector: 'hat-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})

export class CheckListComponent implements OnInit {

  _items = [];
  @ViewChild('itemlist', { static: true }) itemlist: MatSelectionList;
  @Input() set items(data) {
    this.filter = '';
    this._items = data;
    this.filteredItems = data.slice();
  };
  @Input() visible: boolean = true;
  @Input() label: string = '';
  @Input() filter: string = '';
  @Input() listHeight: Number = 240;
  @Output() selectionChange = new EventEmitter<any>();
  public filteredItems = [];
  public applyItems = [];

  constructor(private client: ClientService) { }

  ngOnInit(): void {
    //this.itemlist.selectAll;
    //this.itemlist.deselectAll;
    document.getElementById('inpid').setAttribute('name', this.getRandomName());
  }

  selectionChanged(evt) {
    if (this.selectionChange) {
      const lst = [];
      for (let i = 0; i < this.itemlist.selectedOptions.selected.length; i++) {
        const element = this.itemlist.selectedOptions.selected[i];
        lst.push(element.value);
      }
      this.applyItems = lst;
    }
  }

  applyClick() {
    this.selectionChange.emit(this.applyItems);
  }

  getRandomName() {
    return Math.floor(Math.random() * 1000000).toString();
  }

  filterChanged() {
    this.filteredItems = this._items.filter(e => e.name.toUpperCase().indexOf(this.filter.toUpperCase()) > -1);
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GlobalsService } from '../globals.service';
import { ClientService } from '../client/client.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements CanActivate {

    constructor(
        private router: Router,
        private globals: GlobalsService,
        private client: ClientService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.client.token) {
            // not logged in so redirect to login page with the return url
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            this.client.resetUser();
            if (state.url != '/chat' && state.url.indexOf('/bot') != 0)
                this.router.navigate(['/chat']);
            return true;
        } else {
            return this.client.getCurrentUser()
                .pipe(
                    map(user => {
                        if (user && !user.error) {
                            this.client.currentUser = user;
                            if ((next.data.role.indexOf(user.role) > -1) || state.url == 'admin/user/profile') {
                                // here we are ok
                                return true;
                            } else if (!next.data.role || !next.data.role.length) {
                                return true;
                            } else if (this.client.currentUser && next.data.role.indexOf(this.client.currentUser.role) > -1) {
                                return true;
                            } else {
                                this.globals.showDialog('Error', 'Your role has not permission to perform this action');
                                return false;
                            }
                        } else {
                            this.router.navigate(['admin/login'], { queryParams: { returnUrl: state.url } });
                            return false;
                        }
                    }),
                    catchError(_ => of(false))
                );
        }
    }
}

import { Component } from '@angular/core';
import { GlobalsService } from 'src/app/globals.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(public globals: GlobalsService) { }

}

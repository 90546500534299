import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { Observable } from 'rxjs';
import { ClientService } from 'src/app/client/client.service';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})

export class RateComponent implements OnInit {
  @Input() set rate(d) { this.setRate(d) };
  @Input() minRate: number = 0;
  @Input() maxRate: number = 5;
  @Input() starColor: string = 'black';
  @Input() enabled: boolean = true;
  @Output() rateChange = new EventEmitter<any>();
  stars = [];

  constructor(private client: ClientService) { }

  ngOnInit(): void {
    //this.itemlist.selectAll;
    //this.itemlist.deselectAll;
    //document.getElementById('inpid').setAttribute('name', this.getRandomName());

  }

  setRate(d: number) {
    for (let index: number = this.minRate; index < this.maxRate; index++) {
      this.stars.push(index < d);
    }
  }

  updateRate(d: number) {
    for (let index: number = this.minRate; index < this.maxRate; index++) {
      this.stars[index] = (index < d);
    }

  }

  rateChanged(evt) {
    if (this.enabled) {
      if (this.stars[0] && evt == 1)
        evt = 0;
      this.updateRate(evt);
    }
  }

  apply() {
    if (this.enabled)
      this.rateChange.emit(this.stars.filter(e => e == true).length);
  }

}

import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalsService } from '../globals.service';
import { Observable, of, BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { ResponseStruct, UNDER_TEST, TestEnv } from '../helper-classes/helper';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    private fMenu: any;
    private appMenu: BehaviorSubject<any> = new BehaviorSubject(null);
    private _currentUser: any;
    public API_URL: string;
    public IMAGE_URL: string;
    // =============  Enum ================
    public genders = [];
    public salutations = [];
    public rolesArray = [];
    public userStatusArray = [];
    // ============== menu ===============
    public MENUS = [];
    // ============== manage entities ===============
    public manageEntitiesVar: boolean = false;

    public calcMenu(): Observable<any> {
        return this.appMenu.asObservable();
    }
    public menuChanged(): void {
        this.fixMenu();
        this.appMenu.next(this.fMenu);
    }

    constructor(
        private http: HttpClient,
        private globals: GlobalsService,
        private dialog: MatDialog,
        private router: Router,
        @Inject(UNDER_TEST) private underTest: TestEnv
    ) {
        this.API_URL = environment.apiUrl;
        this.IMAGE_URL = environment.imageUrl;
        this.rolesArray = this.globals.loc.enum_rolesArray; // roles needed at startup even before lang set
        this.globals.getCurrentLangObservable()
            .subscribe(lng => {
                this.onLangChange();
            });
    }

    set token(val: string | null) {
        if (val) {
            sessionStorage.setItem('token', val);
        } else {
            sessionStorage.removeItem('token');
        }
    }
    get token(): string {
        return sessionStorage.getItem('token');
    }

    resetUser() {
        this.currentUser = { role: 'ANONYMOUS', email: null, name: 'Anonymous' };
    }
    set currentUser(val: any) {
        this._currentUser = val;
        if (val) {
            this._currentUser.roleDisp = this.rolesArray.find(e => e.id == this._currentUser.role).name;
        }
    }
    get currentUser() {
        return this._currentUser;
    }

    onLangChange() {
        this.genders = this.globals.loc.enum_genders;
        this.salutations = this.globals.loc.enum_salutations;
        this.rolesArray = this.globals.loc.enum_rolesArray;
        this.userStatusArray = this.globals.loc.enum_user_statusArray;
        this.MENUS = this.globals.loc.enum_MENU;
        if (this._currentUser) {
            this._currentUser.roleDisp = this.rolesArray.find(e => e.id == this._currentUser.role).name;
        }
        this.menuChanged();
    }

    fixMenu(): void {
        // if (!this.currentUser) {
        //     return;
        // }
        this.fMenu = this.MENUS.slice();
        this.fMenu.forEach(el => {
            el.visible = el.roles.indexOf(this.currentUser.role) > -1;
            el.sub.forEach(sub => {
                el.visible = el.visible || sub.roles.indexOf(this.currentUser.role) > -1;
                sub.visible = sub.roles.indexOf(this.currentUser.role) > -1;
            });
        });
    }
    // ============ Login =================
    doLogin(username: string, password: string): Observable<any> {
        return this.httpPOST<any>(this.API_URL + `authenticate`, { username, password }, 'Auth', null, null, true, true);
    }

    doLogout() {
        this.token = null;
        this.resetUser();
        this.menuChanged();
        this.globals.showBtnLoginRegister = true;
    }

    private getDefaultHeaders(fullResponse: boolean): object {
        if (fullResponse) {
            return {
                observe: 'response',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
        } else {
            return {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
        }
    }
    getHeaderAuth(): string {
        return 'Bearer ' + this.token;
    }
    private getDefaultHttpOptions(extraHeaders?: object, respType?: any, noAuth: boolean = false): any {
        const header = {
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
        };
        if (!noAuth) {
            (header as any).Authorization = this.getHeaderAuth();
        }
        // tslint:disable-next-line:prefer-const
        if (extraHeaders) {
            for (const i in extraHeaders) {
                if (extraHeaders.hasOwnProperty(i)) {
                    header[i] = extraHeaders[i];
                }
            }
        }
        if (respType) {
            return { observe: 'response', headers: new HttpHeaders(header), responseType: respType };
        } else {
            return { observe: 'response', headers: new HttpHeaders(header) };
        }
    }
    /**
     *
     * @param url The url : Required
     * @param entity The entity name to included in the error: Optional
     * @param params Http parameters : Optional
     * @param extraHeaders Extra Headers : Optional
     */
    private httpGET<T>(
        url: string,
        entity: string = "Unknown",
        extraHeaders?: HttpHeaders | null,
        responseType?: any,
        noAuth: boolean = false,
        noDialog: boolean = false,
        fullResponse: boolean = false
    ): Observable<T> {
        this.globals.setSpinnerVisible(true);
        return this.http
            .get<T>(
                url,
                this.getDefaultHttpOptions(extraHeaders, responseType, noAuth)
            )
            .pipe(
                map((response) => {
                    const res: any = response;
                    return fullResponse ? res : res.body;
                }),
                tap((_) => this.globals.setSpinnerVisible(false)),
                catchError(this.handleErrorEnch(`GET ${entity}`, !noDialog))
            );
    }
    private httpPOST<T>(
        url: string,
        body: any,
        entity: string = "Unknown",
        extraHeaders?: object | null,
        responseType?: any,
        noAuth: boolean = false,
        noDialog: boolean = false,
        nospin = false,
        fullResponse: boolean = false
    ): Observable<T> {
        if (!nospin) this.globals.setSpinnerVisible(true);
        return this.http
            .post<T>(
                url,
                body,
                this.getDefaultHttpOptions(extraHeaders, responseType, noAuth)
            )
            .pipe(
                map((response) => {
                    const res: any = response;
                    return fullResponse ? res : res.body;
                }),
                tap((_) => {
                    if (!nospin) this.globals.setSpinnerVisible(false);
                }),
                catchError(this.handleErrorEnch(`POST ${entity}`, !noDialog))
            );
    }
    private httpPUT<T>(
        url: string,
        body: any,
        entity: string = "Unknown",
        extraHeaders?: object | null,
        responseType?: any,
        noAuth: boolean = false,
        noDialog: boolean = false
    ): Observable<T> {
        this.globals.setSpinnerVisible(true);
        return this.http
            .put<T>(
                url,
                body,
                this.getDefaultHttpOptions(extraHeaders, responseType, noAuth)
            )
            .pipe(
                map((response) => {
                    const res: any = response;
                    return res.body;
                }),
                tap((_) => this.globals.setSpinnerVisible(false)),
                catchError(this.handleErrorEnch(`PUT ${entity}`, !noDialog))
            );
    }
    private httpDELETE<T>(
        url: string,
        entity: string = "Unknown",
        extraHeaders?: object | null,
        responseType?: any,
        noAuth: boolean = false,
        noDialog: boolean = false
    ): Observable<T> {
        this.globals.setSpinnerVisible(true);
        return this.http
            .delete<T>(
                url,
                this.getDefaultHttpOptions(extraHeaders, responseType, noAuth)
            )
            .pipe(
                map((response) => {
                    const res: any = response;
                    return responseType == "response" ? res : res.body;
                }),
                tap((_) => this.globals.setSpinnerVisible(false)),
                catchError(
                    this.handleErrorEnch(`DELETE ${entity}`, !noDialog, null)
                )
            );
    }
    /**
     *
     * @param operation Text for error structure
     * @param showDialog Display UI or not
     * @param result Data to be returned with the error : Optional
     */
    private handleErrorEnch<T>(operation, showDialog: boolean, result?: T) {
        return (error: any): Observable<ResponseStruct> => {
            if (!operation) {
                operation = 'operation';
            }
            // here could send the error to remote logging infrastructure
            this.globals.setSpinnerVisible(false);
            const err1 = error && error.error ? error.error : error;
            const err2 = err1 && err1.statusText ? err1.statusText : err1.error || 'Unknown error';
            console.error('Http call error: ', JSON.stringify(error));
            if (err1.status == 401) {
                this.doLogout();
                this.router.navigate(['/admin/login']);
            } else if (err1.status == 409) {
                this.globals.showDialog('Error', `${operation} failed because of: "Conflict"`);
                return of({ errorCode: '401', errorMessage: null, data: null });
            }
            else if (error.error && showDialog) { //  in some cases we need to mute dialog
                this.globals.showDialog('Error', `${operation} failed: ${err2}`);
            }
            const _res: ResponseStruct = { errorCode: err1.status, errorMessage: err2, data: null };
            if (result) {
                _res.data = result;
            }
            return of(_res);
        };
    }

    // =============  Chat  =============
    doChat(obj: any): Observable<any> {
        const body: any = obj;
        if (this.currentUser.role == "ADMIN") {
            body.algorithmId = obj.algorithmId;
            body.domainId = obj.domainId;
        }
        return this.httpPOST<any>(
            this.API_URL + "public/chatbot",
            body,
            "Chat",
            null,
            null,
            this.currentUser.role == "ANONYMOUS",
            false,
            true
        );
    }

    getChatDefault(): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(
            this.API_URL + `public/chatbot/defaults`,
            "Chatbot Defaults GET",
            null,
            null,
            true
        );
    }

    updateChatDefaults(obj: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpPUT<any>(
            this.API_URL + `chatbot/defaults`,
            obj,
            "Chatbot Defaults PUT"
        );
    }
    // =============   Users ============
    getUser(id?: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `user` + (id ? `/${id}` : ''), 'User GET')
            .pipe(
                mergeMap(user => {
                    const resp: any = user;
                    if (resp && resp.length) {
                        resp.forEach(el => {
                            this.fixDispDate('createdAt', el, false);
                            el.activeBool = el.status == 'ACTIVE';
                            if (el.profile) {
                                this.fixDispDate('createdAt', el.profile, true);
                                const sx = this.genders.find(e => e.id == el.profile.sex);
                                el.sex_disp = sx ? sx.name : null;
                                el.dob = el.profile.dob;
                                el.language = el.profile.language;
                                el.topics = el.profile.topics;
                            }
                            //delete el.profile;
                        });
                    } else if (id) {
                        this.fixDispDate('createdAt', resp, true);
                        resp.activeBool = resp.status == 'ACTIVE';
                        if (resp.profile) {
                            this.fixDispDate('createdAt', resp.profile, true);
                        } else {
                            resp.profile = { sex: null, dob: 1970, language: 'EN', topics: null, userId: resp.id };
                        }
                    }
                    return of(resp);
                })
            );
    }
    getCurrentUser(): Observable<any> {
        return this.getUser('current');
    }
    saveUser(user: any): Observable<any> {
        if (!user.profile) {
            user.profile = { sex: 'NA', dob: 1970, language: 'EN', topics: '', userId: user.id };
        };
        let profile = JSON.parse(JSON.stringify(user.profile));
        delete user.profile;
        this.globals.setSpinnerVisible(true);
        if (!user.id) {
            return this.httpPOST<any>(this.API_URL + 'user', user, 'User').pipe(
                mergeMap(res_user => {
                    if (res_user) {
                        profile.userId = res_user.id;
                        return this.httpPOST<any>(this.API_URL + 'profile', profile, 'profile')
                    }
                })
            );
        } else {
            return this.httpPUT<any>(this.API_URL + `user/${user.id}`, user, 'User').pipe(
                mergeMap(res_user => {
                    if (res_user) {
                        if (res_user.profile) { // if so PUT profile
                            return this.httpPUT<any>(this.API_URL + `profile/${res_user.profile.id}`, profile, 'User Profile')
                        } else { // post profile
                            return this.httpPOST<any>(this.API_URL + 'profile', profile, 'profile')
                        }
                    }
                })
            );
        }
    }
    deleteUser(obj: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        if (!obj.profile) {
            return this.httpDELETE<any>(this.API_URL + 'user' + `/${obj.id}`, 'Delete User', null, 'response');
        } else {
            return this.httpDELETE<any>(this.API_URL + 'profile' + `/${obj.profile.id}`, 'Delete Profile', null, 'response').pipe(
                mergeMap(res => {
                    return this.httpDELETE<any>(this.API_URL + 'user' + `/${obj.id}`, 'Delete User', null, 'response');
                })
            );
        };
    }

    //Register User
    createUser(user: any): Observable<any> {
        if (!user.profile) {
            user.profile = { sex: 'NA', dob: 1970, language: 'EN', topics: '', userId: user.id };
        };
        let profile = JSON.parse(JSON.stringify(user.profile));
        delete user.profile;
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + 'public/user', user, 'User', null, null, true).pipe(
            mergeMap(res_user => {
                if (res_user) {
                    profile.userId = res_user.id;
                    return this.httpPOST<any>(this.API_URL + 'public/profile', profile, 'profile', null, null, true)
                }
            })
        );
    }

    rating(rate: number, sessionId: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + 'public/chatbot/rating', {
            sessionId: sessionId,
            rating: rate
        }, 'Rating', null, null, true);
    }
    // ========== User History =======================
    getUserHistory(id?: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `user` + (id ? `/${id}` : '') + `/history`, 'User History GET')
            .pipe(
                map(user => {
                    const resp: any = user;
                    resp.forEach((el: any) => {
                        this.fixDispDate('createdAt', el, false);
                        el.domainName = el.domain.name;
                        el.intentName = el.intent.name;
                    })
                    return resp;
                })
            );
    }

    searchHistory(search: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + `user/history/search`, search, 'User History POST');
    }

    // =============   Algorithm ============
    getAlgorithm(id?: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `algorithm` + (id ? `/${id}` : ''), 'Algorithm GET')
            .pipe(
                map(response => {
                    const resp: any = response;
                    if (resp && resp.length) {
                        resp.forEach(el => {
                            this.fixDispDate('createdAt', el, false);
                            el.activeBool = el.status == 'ACTIVE';
                        });
                    } else if (id) {
                        this.fixDispDate('createdAt', resp, false);
                    }
                    return resp;
                })
            );
    }
    saveAlgorithm(user: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        if (!user.id) {
            return this.httpPOST<any>(
                this.API_URL + "algorithm",
                user,
                "Algorithm POST"
            );
        } else {
            return this.httpPUT<any>(
                this.API_URL + `algorithm/${user.id}`,
                user,
                "Algorithm PUT"
            );
        }
    }
    deleteAlgorithm(id: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpDELETE<any>(
            this.API_URL + "algorithm" + `/${id}`,
            "Delete Algorithm",
            null,
            "response"
        );
    }
    // =============   Domain ============
    getDomain(id?: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(
            this.API_URL + `domain` + (id ? `/${id}` : ""),
            "Domain GET"
        ).pipe(
            map((response) => {
                    const resp: any = response;
                    if (resp && resp.length) {
                    resp.forEach((el) => {
                        this.fixDispDate("createdAt", el, false);
                        el.activeBool = el.status == "ACTIVE";
                        });
                    } else if (id) {
                    this.fixDispDate("createdAt", resp, false);
                    }
                    return resp;
                })
            );
    }
    // Domain List
    getDomainList(): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(
            this.API_URL + `domain/admin`,
            "Domain List GET"
        );
    }
    saveDomain(user: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        if (!user.id) {
            return this.httpPOST<any>(
                this.API_URL + "domain",
                user,
                "Domain POST"
            );
        } else {
            return this.httpPUT<any>(
                this.API_URL + `domain/${user.id}`,
                user,
                "Domain PUT"
            );
        }
    }
    deleteDomain(id: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpDELETE<any>(
            this.API_URL + "domain" + `/${id}`,
            "Delete Domain",
            null,
            "response"
        );
    }

    // =============   Entity ============
    getEntity(id?: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `entity` + (id ? `/${id}` : ''), 'Entity GET')
            .pipe(
                map(response => {
                    const resp: any = response;
                    if (resp && resp.length) {
                        resp.forEach(el => {
                            this.fixDispDate('createdAt', el, false);
                        });
                    } else if (id) {
                        this.fixDispDate('createdAt', resp, false);
                    }
                    return resp;
                })
            );
    }
    saveEntity(user: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        if (!user.id) {
            return this.httpPOST<any>(this.API_URL + 'entity', user, 'Entity POST');
        } else {
            return this.httpPUT<any>(this.API_URL + `entity/${user.id}`, user, 'Entity PUT');
        }
    }
    deleteEntity(id: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpDELETE<any>(this.API_URL + 'entity' + `/${id}`, 'Delete Entity', null, 'response');
    }
    manageEntities(intent_id: string, domain_id: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `intent` + `/${intent_id}` + `/${domain_id}`, 'Real Entities GET')
            .pipe(
                map(response => {
                    const resp: any = response;
                    if (resp && resp.length) {
                        resp.forEach(el => {
                            this.fixDispDate('createdAt', el, false);
                        });
                        return resp;
                    }
                }
                ));
    }
    async addEntityRelation(entity: any): Promise<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + 'intent/relation', entity, 'ADD ENTITY POST').toPromise();
    }
    async deleteEntityRelation(relation_id: string): Promise<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpDELETE<any>(this.API_URL + 'intent/relation' + `/${relation_id}`, 'Delete Entity Relation', null, 'response').toPromise();
    }
    // =============   Intent ============
    getIntent(id?: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `intent` + (id ? `/${id}` : ''), 'Intent GET')
            .pipe(
                map(response => {
                    const resp: any = response;
                    if (resp && resp.length) {
                        resp.forEach(el => {
                            this.fixDispDate('createdAt', el, false);
                        });
                    } else if (id) {
                        this.fixDispDate('createdAt', resp, false);
                        // temp code for debuging the Intent Label
                        // let q = resp.questions || [];
                        // q.forEach(e => {
                        //     let a = e.answers || [];
                        //     a.forEach(an => {
                        //         an.label = an.label + ' lab';
                        //         if (an.dependentQuestion) {
                        //             let a1 = an.dependentQuestion.answers || [];
                        //             a1.forEach(an1 => {
                        //                 an1.label = an1.label + ' lab';
                        //             });
                        //         };
                        //     });
                        // });
                        //
                    }
                    return resp;
                })
            );
    }
    saveIntent(user: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        if (!user.id) {
            return this.httpPOST<any>(this.API_URL + 'intent', user, 'Intent POST');
        } else {
            return this.httpPUT<any>(this.API_URL + `intent/${user.id}`, user, 'Intent PUT');
        }
    }
    saveIntentRelations(data: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + 'intent' + `/${data.intentId}` + '/entities', data, 'Intent Relations POST');
    }
    deleteIntent(id: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpDELETE<any>(this.API_URL + 'intent' + `/${id}`, 'Delete Intent', null, 'response');
    }

    getIntentRel(): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + 'intent/relation', 'Intent GET')
            .pipe(
                map(response => {
                    const resp: any = response;
                    response.forEach((e: any) => {
                        e.intentName = e.intent ? e.intent.name : null;
                        e.domainName = e.domain ? e.domain.name : null;
                    })
                    return resp;
                }));
    }
    // =========== Dataset / Sentence Labelling =============
    getIntEnt(domain_id: string): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `domain` + `/${domain_id}` + `/relations`, 'Intent Entities GET')
            .pipe(
                map(response => {
                    const resp: any = response;
                    return resp;
                }
                )
            )
    }

    saveSentenceLabelling(sentence: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + 'dataset', sentence, 'Sentence Labelling POST', null, "response");
    }

    getListDatasets() {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `dataset`, 'Dataset GET')
            .pipe(
                map(response => {
                    const resp: any = response;
                    response.forEach((e: any) => {
                        e.domainName = e.domain ? e.domain.name : null;
                        e.userId = e.createdBy ? e.createdBy.id : null;
                    })
                    return resp;
                }
                )
            )
    }

    mergeDataset(dataset: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + `dataset` + `/${dataset.id}` + `/merge`, dataset, 'Merge Dataset POST');
    }

    deleteDataset(dataset: any): Observable<any> {
        this.globals.setSpinnerVisible(true);
        return this.httpDELETE<any>(this.API_URL + `dataset` + `/${dataset.id}`, 'Delete Dataset', null, 'response');
    }

    //dataset import
    importDataset(dataset: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + `dataset/import`, dataset, 'import Dataset POST');
    }
    //get models
    getModels() {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `model`, 'models GET');
    }
    //get training Results
    getTrainingResults(id) {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(this.API_URL + `model` + `/${id}`, 'Get training result');
    }
    //Save Training Results
    saveModelsResults(id) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<[]>(this.API_URL + `model` + `/${id}`, 'Save Training Results Post')
    }
    //delete Training Results
    deleteModelsResults(id) {
        this.globals.setSpinnerVisible(true);
        return this.httpDELETE<[]>(this.API_URL + `model` + `/${id}`, 'delete Training Results delete')
    }

    //retraining
    filterDatasets(data: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<[]>(this.API_URL + 'dataset/filter', data, 'get filter datasets POST').pipe(
            map(response => {
                const resp: any = response;
                response.forEach((e: any) => {

                    e.userName = e.createdBy ? e.createdBy.name : null;
                })
                return resp;
            }
            )
        );
    }

    trainModel(data: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + `model/train`, data, 'Train model POST',null,null,false,false,false,true);
    }

    // ========== NRE & IE =======================
    saveNre(nre: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(this.API_URL + `nre`, nre, 'NRE & IE POST');
    }

    // =========== Download files =================
    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    public doDownloadFile(data: any, type: string, filename?: string) {
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        // for popup var pwa = window.open(url);
        // for popup if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        //  for popup    alert( 'Please disable your Pop-up blocker and try again.');
        // for popup }
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename || "filename.zip";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
    downloadShip(id: string, name: string) {
        const url = this.API_URL + `ship/download`;
        return this.httpPOST(url, { id }, "POST download ship").subscribe(
            (res) =>
                this.doDownloadFile(
                    JSON.stringify((res as any).data),
                    "application/json",
                    name
                )
        );
    }
    downloadCSV() {
        const url = this.API_URL + `resources/csvdownload`;
        return this.httpGET(url, "GET DB dump", null, "blob").subscribe((res) =>
            this.doDownloadFile(res, "application/zip", "Archive.zip")
        );
    }
    changePassword(userId: string, newPass): Observable<any> {
        return this.httpPOST<any>(
            this.API_URL + "user/changepassword",
            { id: userId, newPassword1: newPass },
            "Password change"
        );
    }
    validateResetPassword(uid: string): Observable<any> {
        return this.httpPOST<any>(
            this.API_URL + "password/checkvalid",
            { uid },
            "Password reset check",
            null,
            null,
            true
        );
    }
    requestResetPassword(email: string): Observable<any> {
        return this.httpPOST<any>(
            this.API_URL + "password/request",
            { email, mb: true },
            "Password reset request",
            null,
            null,
            true
        );
    }
    resetPassword(pass: string, uid: string): Observable<any> {
        return this.httpPOST<any>(
            this.API_URL + "password/reset",
            { uid, newPassword1: pass, mb: true },
            "Password reset action",
            null,
            null,
            true
        );
    }
    private fixDispDate(fieldName: string, obj: any, withTime = true) {
        const extraTimeFormat = withTime ? " HH:mm:ss" : "";
        obj[fieldName + "_disp"] = obj[fieldName]
            ? moment(obj[fieldName]).format(
                  this.globals.loc.dateFormat + extraTimeFormat
              )
            : null;
    }

    sentEmailToReset(body: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(
            this.API_URL + `public/password/reset`,
            body,
            "Reset Password Post",
            null,
            null,
            true,
            true
        );
    }
    //Check reset password token
    checkResetToken(id: string) {
        this.globals.setSpinnerVisible(true);
        return this.httpGET<[]>(
            this.API_URL + `public/password/reset/${id}`,
            "Get reset password token",
            null,
            null,
            true,
            true
        );
    }

    //Reset password
    resetForgotPassword(body: any, id: string) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(
            this.API_URL + `public/password/reset/${id}`,
            body,
            "Reset Forgot Password Post",
            null,
            null,
            true,
            true
        );
    }

    //statistics
    getStatistics(body?: any) {
        this.globals.setSpinnerVisible(true);
        return this.httpPOST<any>(
            this.API_URL + `public/chatbot/statistics`,
            body,
            "Get ALL statistics ",
            null,
            null,
            true,
            true
        );
    }
}

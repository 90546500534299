import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from 'src/app/globals.service';
import { AuthService } from '../../auth/auth-service.service';
import { ClientService } from '../../client/client.service';
import { SubSink } from 'subsink';
import { TestEnv, UNDER_TEST } from 'src/app/helper-classes/helper';

@Component({
    selector: 'app-base',
    template: 'NO-TEMPLATE'
})
export class BaseComponent implements OnInit, OnDestroy {
    protected subsink = new SubSink();
    protected underTest: TestEnv;

    constructor(
        protected injector: Injector,
        protected routeParams: ActivatedRoute,
        public globals: GlobalsService,
        public auth: AuthService,
        public client: ClientService,
        protected router: Router
    ) { }

    ngOnInit() {
        this.underTest = this.injector.get<TestEnv>(UNDER_TEST);
        this.globals.setMenuVisible(true);
        this.client.menuChanged();
        if (!this.underTest.blockData) {
            this.loadData();
        }
        this.subsink.sink = this.globals.getCurrentLangObservable()
            .subscribe(lng => {
                this.onLangChange();
            });
    }

    protected onLangChange() {
        // intentionaly left empty
    }

    ngOnDestroy() {
        this.globals.setSpinnerVisible(false);
        this.subsink.unsubscribe();
    }

    protected loadData() {
        //
    }

    protected afterLoadData() {
        //
    }

    // protected _filterCountry(value: string): Country[] {
    //     const filterValue = value.toLowerCase();
    //     return this.countries.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    // }

    // public displayFnCountry(country?: Country): string | undefined {
    //     return country ? country.name : undefined;
    // }

    public compareFn(v1: any, v2: any): boolean {
        return v1 && v2 ? v1.id === v2.id : v1 === v2;
    }

}

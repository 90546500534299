import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-list-filter',
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent {

     /** Link text */
     @Input() placeholderText = 'Filter';
     @Input() filterText = '';
     @Input() isDisabled = false;
     @Input() storageKey = 'none';
     @Output() doOnChange = new EventEmitter<any>();

    constructor() {
        // constractor code here
    }

    public applyFilter(filterValue: any) {
        this.filterText = filterValue.value || '';
        this.doOnChange.emit(this.filterText);
    }

    public handleFilter(save: boolean): void {
        if (save) {
            localStorage.setItem(this.storageKey, this.filterText);
        } else {
            localStorage.removeItem(this.storageKey);
            this.applyFilter('');
        }
    }
}

import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { environment } from '../environments/environment';
import { GR } from './languages/GR';
import { EN } from './languages/EN';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimpleDialogComponent } from './dialogs/simple/simple.component';
import { UNDER_TEST, TestEnv } from './helper-classes/helper';

@Injectable({
    providedIn: 'root'
})
export class GlobalsService {
    private env = environment;
    private menuVisible: BehaviorSubject<any> = new BehaviorSubject(null);
    private spinnerVisible: BehaviorSubject<any> = new BehaviorSubject(null);
    private isFrameSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private currentLanguage = new ReplaySubject<string>(1);
    public loc = EN;
    public _currentLang: any;
    public availableLanguages = [
        { label: 'English', value: 'EN' },
        { label: 'Ελληνικά', value: 'GR' }
    ];

    private _showBtnLoginRegister: boolean;
    public get showBtnLoginRegister(): boolean {
        return this._showBtnLoginRegister;
    }
    public set showBtnLoginRegister(v: boolean) {
        this._showBtnLoginRegister = v;
    }


    public getMenuStatus(): Observable<any> {
        return this.menuVisible.asObservable();
    }
    public getSpinnerStatus(): Observable<any> {
        return this.spinnerVisible.asObservable();
    }
    public getIsFrameSub(): Observable<any> {
        return this.isFrameSub.asObservable();
    }
    public setIsFrame(b: boolean) {
        this.isFrameSub.next(b);
    }

    get isMessenger() {
        return (window as any).isMessengerSupported;
    };
    get currentLang() {
        return localStorage.getItem('currentLang') || 'EN';
    }
    set currentLang(v: any) {
        this._currentLang = v;
        localStorage.setItem('currentLang', v);
        switch (v) {
            case 'GR':
                this.loc = GR;
                break;
            case 'EN':
                this.loc = EN;
                break;
            default:
                this.loc = EN;
                break;
        }
        this.currentLanguage.next(v);
    }

    getCurrentLangObservable() {
        return this.currentLanguage.asObservable();
    }

    constructor(
        private dialog: MatDialog,
        @Inject(UNDER_TEST) public underTest: TestEnv
    ) { }

    public setMenuVisible(isVisible: boolean): void {
        this.menuVisible.next(isVisible);
    }

    public setSpinnerVisible(isVisible: boolean): void {
        this.spinnerVisible.next(isVisible);
    }

    public showDialog(title: string, body: string, actions?: any, callBack?: any): MatDialogRef<SimpleDialogComponent> {
        const dialogRef =
            this.dialog.open(SimpleDialogComponent, {
                data: {
                    title: title,
                    body: body,
                    actions: actions || [{ text: this.loc.gl_ok, result: 1 }]
                }
            });
        dialogRef.afterClosed().subscribe(result => {
            if (callBack) {
                callBack(result);
            }
        });
        if (this.underTest.test) {
            setTimeout(() => {
                dialogRef.close('');
            }, 100);
        }
        return dialogRef;
    }

    arrayObjUnique(array, field) {
        return array.reduce((accumulator, current) => {
            if (!accumulator.includes(current[field])) {
                accumulator.push(current[field])
            }
            return accumulator;
        }, []
        )
        /*
        outData.reduce((accumulator, current) => {
                        var key = current.upload_month;
                        if (!outDataMonth[key]) {
                            outDataMonth[key] = current.uploads
                            accumulator.push({ month: key, uploads: outDataMonth[key] });
                        } else {
                            outDataMonth[key] += current.uploads;
                        }
                        return accumulator;
                    }, []);
         */
    }
    arrayUnique(array) {
        return array.reduce((accumulator, current) => {
            if (!accumulator.includes(current)) {
                accumulator.push(current)
            }
            return accumulator;
        }, []
        )
    }
    arrayGroup(array, field) {
        return array.reduce((accumulator, current) => {
            if (!accumulator.hasOwnProperty(field)) {
                accumulator[field] = 0;
            }
            accumulator[field]++;
            return accumulator;
        }, {})
    }
    objArrayGroup(array, field) {
        const obj = array.reduce((accumulator, current) => {
            const f = current[field];
            if (!accumulator.hasOwnProperty(f)) {
                accumulator[f] = [];
            }
            accumulator[f].push(current);
            return accumulator;
        }, {})
        const res = [];
        for (const key in obj) {
            const el = obj[key];
            const outObj = { data: el };
            outObj[field] = key;
            res.push(outObj);
        }
        return res;
    }

}

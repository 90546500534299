import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from 'src/app/globals.service';
import { AuthService } from 'src/app/auth/auth-service.service';
import { ClientService } from 'src/app/client/client.service';
import { TestEnv, UNDER_TEST } from 'src/app/helper-classes/helper';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-base-entity',
    template: 'NO-TEMPLATE'
})
export class BaseEntityComponent implements OnInit, OnDestroy {

    public canEdit = false;
    public paramId: string;
    protected paramsAll: any;
    public entity: any;
    protected unsub$ = new SubSink();
    protected routerSubscription: Subscription;
    public underTest: TestEnv;

    constructor(
        protected injector: Injector,
        protected routeParams: ActivatedRoute,
        public globals: GlobalsService,
        public auth: AuthService,
        public client: ClientService,
        protected router: Router
    ) { }

    // ngAfterViewInit() {
    // (<HTMLInputElement>document.getElementById('curriculaID')).value = '123344565';
    // }

    ngOnInit() {
        this.underTest = this.injector.get<TestEnv>(UNDER_TEST);
        this.routerSubscription = this.routeParams.paramMap.subscribe(params => {
            this.paramId = params.get('id');
            this.paramsAll = params;
            this.globals.setMenuVisible(true);
            this.client.menuChanged();
            if (!this.underTest.blockData) {
                this.loadData();
            }
        });
    }

    ngOnDestroy() {
        this.globals.setSpinnerVisible(false);
        this.unsub$.unsubscribe();
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    protected loadData() {
        //
    }

    // protected _filterCountry(value: string): Country[] {
    //     const filterValue = value.toLowerCase();
    //     return this.countries.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    // }

    // public displayFnCountry(country?: Country): string | undefined {
    //     return country ? country.name : undefined;
    // }

    protected _filterLookup(value: string, data: any): any[] {
        const filterValue = value.toLowerCase();
        return data.filter(mt => mt.name.toLowerCase().indexOf(filterValue) === 0);
    }

    public compareFn(v1: any, v2: any): boolean {
        return v1 && v2 ? v1.id === v2.id : v1 === v2;
    }

    public displayFnLookup(typ?: any): string | undefined {
        return typ ? typ.name : undefined;
    }

}

import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [],
    imports: [MatButtonModule, MatCheckboxModule, MatDialogModule, MatToolbarModule, MatCardModule, MatInputModule,
        MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatMenuModule, MatIconModule,
        MatProgressSpinnerModule, MatSelectModule, MatAutocompleteModule, MatDatepickerModule, MatMomentDateModule,
        MatTabsModule, MatRadioModule, MatListModule, MatStepperModule, MatTooltipModule, MatSliderModule, DragDropModule,
        MatProgressBarModule, MatSlideToggleModule
    ],
    exports: [MatButtonModule, MatCheckboxModule, MatDialogModule, MatToolbarModule, MatCardModule, MatInputModule,
        MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatMenuModule, MatIconModule,
        MatProgressSpinnerModule, MatSelectModule, MatAutocompleteModule, MatDatepickerModule, MatMomentDateModule,
        MatTabsModule, MatRadioModule, MatListModule, MatStepperModule, MatTooltipModule, MatSliderModule, DragDropModule,
        MatProgressBarModule, MatSlideToggleModule
    ],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ],
})
export class MatModule { }

import { Component, OnInit, OnDestroy, Injector, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/base/base/base.component';
import { Location as LocationService } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from 'src/app/globals.service';
import { AuthService } from 'src/app/auth/auth-service.service';
import { ClientService } from 'src/app/client/client.service';
import { BehaviorSubject } from 'rxjs';
import { Ichat } from 'src/app/helper-classes/helper';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

    constructor(
        protected injector: Injector,
        protected routeParams: ActivatedRoute,
        public globals: GlobalsService,
        public auth: AuthService,
        public client: ClientService,
        protected router: Router
    ) {
        super(injector, routeParams, globals, auth, client, router);
    }

    protected onLangChange(): void {
        super.onLangChange();
        //
    }

    protected loadData(): void {
        super.loadData();
        this.client.fixMenu();
        this.afterLoadData();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}

export const GR = {
    dateFormat: "YYYY-MM-DD",
    gl_required: "Απαιτείται",
    gl_submit: "Υποβολή",
    gl_error: "Πρόβλημα!",
    gl_warning: "Προσοχή!",
    gl_information: "Πληροφόρηση!",
    gl_confirmation: "Επιβεβαίωση!",
    gl_yes: "Ναι",
    gl_no: "Όχι",
    gl_cancel: "Ακύρωση",
    gl_cancel_exit: "Ακύρωση & έξοδος",
    gl_cancel_confirm: "Ακύρωση τελευταίων αλλαγών. Είστε σίγουροι?",
    gl_show: "Δείξε",
    gl_ok: "Ok",
    gl_done: "Έτοιμο",
    gl_exit: "Έξοδος",
    gl_apply: "Εφαρμογή",
    gl_save: "Αποθήκευση",
    gl_saveStay: "Αποθήκευση & παραμονή",
    gl_save_exit: "Αποθήκευση & έξοδος",
    gl_is: "είναι",
    gl_delete: "Διαγραφή",
    gl_new: "Νέα εγγραφή",
    gl_edit: "Μεταβολή",
    gl_reset: "Επαναφορά",
    gl_post: "Καταχώρηση",
    gl_manage: "Διαχείριση Οντοτήτων",
    gl_export_all: "Εξαγωγή όλων",
    gl_export_selected: "Εξαγωγή επιλεγμένων μόνο",
    gl_total_records: "εγγραφές",
    gl_rows_selected: "επιλεγμένες σειρές",
    gl_error_permisions:
        "Ο ρόλος σας δεν επιτρέπει να εκτελεστεί αυτή την εργασία",
    gl_str_poor: "Αξιολόγηση: Φτωχό",
    gl_str_notgood: "Αξιολόγηση: Όχι τόσο καλό",
    gl_str_average: "Αξιολόγηση: Μέτριο",
    gl_str_good: "Αξιολόγηση: Καλό",
    gl_str_rule:
        ". Απαιτούνται τουλάχιστον οι χαρακτήρες: 1 Κεφαλαίος, 1 πεζός, 1 αριθμός, 1 σημ. στίξης, 6 χαρ. μήκος",
    gl_export: "Εξαγωγή CSV",
    gl_delete_confirm: "Διαγραφή εγγραφής? Παρακαλούμε επιβεβαιώστε.",
    gl_delete_confirm_multi:
        "Διαγραφή πολλαπλών εγγραφών? Παρακαλούμε επιβεβαιώστε.",
    gl_deleted_ok: "Η εγγραφή διεγράφη με επιτυχία.",
    gl_discard_changes:
        "Υπάρχουν αλλαγές που εκκρεμούν. Απορρίπτετε αυτές τις αλλαγές;",
    gl_exit_confirm: "Επιστροφή. Είστε σίγουροι?",
    gl_saved_ok: "Η εγγραφή αποθηκεύτηκε με επιτυχία.",
    gl_filter: "Φίλτρο",
    gl_already_exist:
        ' Υπάρχουν ήδη σχέσεις γι αυτή την πρόθεση και domain. Παρακαλούμε χρησιμοποιήστε την λειτουργία "Διαχείριση Οντοτήτων, για να διαχειριστείτε τις σχέσεις.',
    gl_copied_to_clipboard: "Ο σύνδεσμος αντιγράφηκε στο clipboard.",

    app_title: "Bell Boy Bot",
    app_title1: "Chatbot για τον τουρισμό",
    app_cookies1: "Η ιστοσελίδα",
    app_cookies2: `χρησιμοποιεί cookies για καλύτερη εμπειρία πλοήγησης. Για να μάθετε περισσότερα παρακαλούμε διαβάστε τους`,
    app_cookies3: "Όρους Χρήσης.",
    app_cookies_btn: "Συμφωνώ",

    app_footer1: "Όροι χρήσης",
    app_footer2: "Χάρτης ιστότοπου",
    app_footer3: "15124, Μαρούσι, Αθήνα - All Rights Reserved.",

    menu_entities: "Οντότητες",
    menu_policies: "Πολιτικές",
    menu_settings: "Ρυθμίσεις",
    menu_current_user: "Χρήστης: ",
    menu_current_role: "Ρόλος: ",
    menu_login: "Είσοδος",
    menu_register: "Εγγραφή",
    menu_change_password: "Αλλαγή κωδικού",
    menu_logout: "Αποσύνδεση",
    menu_enter_text: "Εισαγάγετε κείμενο εδώ",
    menu_send_button: "ΑΠΟΣΤΟΛΗ",
    menu_bot_text1: "Γειά σας. Είμαι ο BBB.",
    menu_bot_text2: `Πληκτρολογήστε κάτι σαν "Πρότεινε ξενοδοχείο στην Αθήνα"`,

    login_card_header: "Είσοδος χρήστη",
    admin_login_card_header: "Είσοδος διαχειριστή",
    login_email: "Email χρήστη",
    login_password: "Κωδικός",
    login_forgot_password: "Ξέχασα τον κωδικό μου",
    login_submit: "Υποβολή",
    login_error_email: "Παρακαλούμε, εισάγετε ένα έγκυρο email",
    login_error_password: "Παρακαλούμε, εισάγετε έναν έγκυρο κωδικό",
    login_error_invalid_credentials: "Αποτυχία σύνδεσης. ",
    logout_confirm: "Αποσύνδεση. Παρακαλούμε επιβεβαιώστε",
    forgot_password: "Ξεχάσατε τον κωδικό πρόσβασης;",
    forgot_password_text:
        " Εισάγετε το email σας και θα σας στείλουμε οδηγίες για το πώς να επαναφέρετε τον κωδικό πρόσβασής σας. Βεβαιωθείτε ότι πληκτρολογήσατε σωστά το email σας και ότι είναι το ίδιο με αυτό που χρησιμοποιήσατε για την εγγραφή σας. ",

    notfound_header: "Η σελίδα δε βρέθηκε!",
    reset_password_title: "Επαναφέρετε τον κωδικό πρόσβασης",
    reset_password_new: "Επιβεβαίωση Κωδικού",
    reset_password_retype: "Πληκτρολογήστε ξανά νέο κωδικό πρόσβασης",
    reset_password_error: "Σφάλμα",
    reset_password_error_body: "Δεν υπάρχει χρήστης με παρεχόμενο email",
    reset_password_success: "Επιτυχία!",
    reset_password_success_body:
        "Έχει σταλεί ένα email που περιέχει έναν σύνδεσμο για την επαναφορά του κωδικού πρόσβασης",
    reset_password_invalid_uuid: "Παρέχεται μη έγκυρο uuid",
    reset_password_expired_uuid: "Ο σύνδεσμος έχει λήξει",
    reset_password_successful_title: "Ο κωδικός άλλαξε!",
    reset_password_successful_body:
        "Ο κωδικός πρόσβασής σας άλλαξε με επιτυχία",
    reset_password_not_valid:
        "Ο κωδικός πρόσβασης και ο κωδικός επιβεβαίωσης πρέπει να ταιριάζουν",
    password_change_card_header: "Αλλαγή κωδικού",
    password_reset_card_header: "Επαναφορά κωδικού",
    password_old: "Τρέχων κωδικός",
    password_new: "Νέος κωδικός",
    password_repeat: "Επανάληψη κωδικού",
    password_error_old: "Παρακαλούμε εισάγετε τον τρέχοντα κωδικό σας",
    password_error_new:
        "Παρακαλούμε εισάγετε το νέο κωδικό σας (ελάχιστο 6 χαρακτήρες)",
    password_error_repeat:
        "Παρακαλούμε επαν-εισάγετε το νέο κωδικό σας (ελάχιστο 6 χαρακτήρες)",
    password_error_not_same:
        "Ο νέος κωδικός πρέπει να είναι ίδιος με αυτόν στο πεδίο `Επανάληψη κωδικού`!",
    password_directions_sent:
        "Έχουν σταλλεί οδηγίες στο email σας. Ακολοθείστε τις προσεκτικά για να επαναφέρετε τον κωδικό σας.",
    password_relogin:
        "Ο κωδικός σας άλλαξε. Παρακαλούμε εισέλθετε στην εφαρμογή με το νέο σας κωδικό.",
    password_link_invalid:
        'Ο σύνδεσμος είναι λανθασμένος ή έχει λήξει. Πρέπει να επαναλάβετε τη διαδικασία "Ξέχασα τον κωδικό" ώστε να λάβετε ένα νέο σύνδεσμο!',
    password_change_failed:
        "Ο κωδικός ΔΕΝ αλλαξε! Παρακαλούμε προσπαθήστε πάλι με το σωστό υπάρχοντα κωδικό!",

    account_activate_card_header: "Ενεργοποίηση λογαριασμού",
    account_activate_link_invalid:
        "Ο σύνδεσμος είναι λανθασμένος ή έχει λήξει. Πρέπει να επικοινωνήσετε με τον διαχειριστή του ιστότοπου, ώστε να λάβετε ένα νέο σύνδεσμο!",
    account_activate_login:
        "Ο λογαριασμός σας ενεργοποιήθηκε. Μπορείτε πλέον να εισέλθετε στο σύστημα με τα διαπιστευτήριά σας.",

    user_list_caption: "Λίστα χρηστών",
    user_list_new_user: "Νέος χρήστης",
    user_list_field_captions: [
        "Email",
        "Όνομα",
        "Ρόλος",
        "Ενεργός",
        "Έτος γέν.",
        "Φύλο",
        "Γλώσα",
        "Θέματα",
        "Ημ. Εγγραφής",
    ],

    // USER
    user_ent_caption: "Χρήστης",
    user_ent_name: "Όνομα",
    user_ent_email: "Email",
    user_ent_role: "Ρόλος",
    user_ent_status: "Κατάσταση",
    user_ent_created: "Ημ. Εγγραφής",
    user_ent_password: "Κωδικός",
    user_ent_set_password: "Καταχώρηση νέου κωδικού",
    user_ent_set_password_error: "Ο Κωδικός απαιτείται για νέο χρήστη",
    user_ent_sex: "Φύλο",
    user_ent_language: "Γλώσσα",
    user_ent_dob: "Έτος γέννησης",
    user_ent_dob_error: "Το έτος γέννησης πρέπει να έχει τιμή 1920-2050",
    user_ent_topics: "Θέματα",

    user_history_list_field_captions: [
        "UserId",
        "Ημερομηνία",
        "Domain",
        "Domain Id",
        "Intent",
        "Intent Id",
        "Predicted Entities",
        "Predicted Values",
        "Κείμενο",
        "Απάντηση",
    ],
    user_history_caption: "Ιστορία Χρήστη",
    user_history_search_btn: "Αναζήτηση",
    user_history_answer: "Περιεχόμενο Απάντησης",

    // Algorithm
    algorithm_list_caption: "Λίστα αλγορίθμων",
    algorithm_list_new_algorithm: "Νέος Αλγόριθμος",
    algorithm_list_field_captions: [
        "Όνομα",
        "Περιγραφή",
        "Ημ. Εγγραφής",
        "CustomId",
    ],

    algorithm_ent_caption: "Αλγόριθμος",
    algorithm_ent_name: "Όνομα",
    algorithm_ent_descr: "Περιγραφή",
    algorithm_ent_created: "Ημ. Εγγραφής",

    // Domain
    domain_list_caption: "Λίστα Domains",
    domain_list_new_domain: "Νέο domain",
    domain_list_field_captions: [
        "Όνομα",
        "Περιγραφή",
        "Ορατό",
        "Ημ. Εγγραφής",
        "Custom id",
    ],

    domain_ent_caption: "Domain",
    domain_ent_name: "Όνομα",
    domain_ent_descr: "Περιγραφή",
    domain_ent_created: "Ημ. Εγγραφής",
    domain_ent_customId: "Custom ID",

    //Sentence Labelling

    sentence_dataset: "Όνομα συνόλου δεδομένων",
    sentence_dataset_descr: "Περιγραφή συνόλου δεδομένων",
    sentence_title: "Ετικέτες προτάσεων",
    sentence_domain: "Domain",
    sentence_load_int_ent: "Φόρτωση Προθέσεων και Οντοτήτων",
    sentence_add: "Προσθήκη Πρότασης",
    sentence_save: "Αποθήκευση Ετικετών",
    sentence_warning:
        "Ξεκλειδώνοντας την πρόταση θα χάσετε όλες τις σχέσεις που έχετε δημιουργήσει. Είστε βέβαιοι ότι θέλετε να ξεκλειδώσετε την πρόταση;",
    sentence_entity: "Oντότητα",
    sentence_sentec: "Προτάσεις",
    sentence_use: "Χρήση Πρόταση",
    sentence_fileload: "Φόρτωση Αρχείου",
    sentence_upload_title:
        "Ανεβάστε ένα αρχείο κειμένου (example.txt) με προτάσεις.",
    sentence_choose_file: "Επιλέξτε Αρχείο",

    //Datasets
    dataset_list_field_captions: [
        "Id",
        "Όνομα",
        "Όνομα Domain",
        "Χρήστης",
        "Γλώσσα",
        "Ημέρα ώρα",
        "Αλγόριθμος",
        "Σύνδεσμος για 3 αρχεία",
        "Master",
        "Action",
    ],
    dataset_list_caption: "Λίστα Συνόλων Δεδομένων",
    dataset_corpus_file: "Περιεχόμενο Corpus",
    dataset_entities_file: "Περιεχόμενο Οντοτήτων",
    dataset_intents_file: "Περιεχόμενο Προθέσεων",
    dataset_merge_popupTxt:
        "Θέλετε να συγχωνεύσετε αυτό το σύνολο δεδομένων; Παρακαλώ Επιβεβαιώστε",
    dataset_delete_popupTxt:
        "Θέλετε να διαγράψετε αυτό το σύνολο δεδομένων; Παρακαλώ Επιβεβαιώστε",
    dataset_name: "Dataset Όνομα",
    dataset_desc: "Dataset Περιγραφή",
    dataset_merge: "Συγχώνευση",
    datasets_import: "Εισαγωγή",
    datasets_submit: "Αποθήκευση Dataset",
    datasets_info: "Πληροφορίες",
    datasets_msg: "H εισαγωγή ήταν επιτυχής",
    datasets_οκ: "ΟΚ",

    // Entity
    entity_list_caption: "Λίστα Οντοτήτων",
    entity_list_new_entity: "Νέα οντότητα",
    entity_list_field_captions: ["Όνομα", "Ημ. Εγγραφής"],
    entity_field_task: "Έργο",

    entity_ent_caption: "Οντότητα",
    entity_ent_name: "Όνομα",
    entity_ent_created: "Ημ. Εγγραφής",
    entity_ent_question_en: "Ερώτηση(Αγγλικά)",
    entity_ent_question_gr: "Ερώτηση(Ελληνικά)",

    results_caption: "Results: ",

    // Intent
    intent_list_caption: "Λίστα Προθέσεων",
    intent_list_new_intent: "Νέα Πρόθεση",
    intent_rel_caption: "Σχέσεις Προθέσεων",
    intent_list_field_captions: ["Όνομα", "Ημ. Εγγραφής"],
    intent_rel_field_captions: ["Πρόθεση", "Domain", "Οντότητες", "Actions"],
    intent_relation_new_relation: ["Νέα Σχέση"],
    intent_continue_rel: "Συνεχίστε στις σχέσεις",

    intent_tab_gen_caption: "Γενικά",
    intent_tab_rel_caption: "Σχέσεις",
    intent_ent_caption: "Πρόθεση",
    intent_ent_name: "Όνομα",
    intent_ent_created: "Ημ. Εγγραφής",
    intent_en: "Πρότυπο απαντήσεων Αγγλικά",
    intent_el: "Πρότυπο απαντήσεων Ελληνικά",
    intent_example: "Παράδειγμα απάντησης: Ο συντελεστής για το μάθημα",

    intent_action_1: "Προσθ. Απαραίτητης",
    intent_action_2: "Προσθ. Προαιρετικής",
    intent_action_3: "Αφαίρεση",

    propertyName_caption: "Όνομα property",
    questions_caption: "Ερωτήσεις",
    question_caption: "Ερώτηση",
    answer_caption: "Απάντηση",
    answer_code: "Κωδικός απάντησης.",
    question_remove_question_block: "Διαγραφή ομάδας ερώτησης",
    question_remove_sub_question_block: "Διαγραφή υπο-ομάδας ερώτησης",
    rate_prompt: 'Παρακαλούμε αξιολογήστε το bot μας, σε σχέση με τη συνολική εμπειρία σας.',
    rate_thanks: 'Ευχαριστούμε για την αξιολόγηση.',

    // ChatBot Config
    chatbot_config_title: "Διαμόρφωση Chatbot",

    //performance
    performance_title: "Αποτέλεσμα απόδοσης",
    performance_list_field_captions: [
        "ID",
        "Συνόλων Δεδομένων",
        "Όνομα αλγορίθμου",
        "Όνομα τομέα",
        "Ημερομηνία Δημιουργίας",
        "Γλώσσα",
        "Κατάσταση",
        "Ενέργειες",
    ],
    performance_list_field_results: [
        "Τύπος",
        "Όνομα",
        "Old Accuracy",
        "Accuracy",
        "Old Precision",
        "Precision",
        "Old f1",
        "f1",
    ],
    performance_list_action: "View",
    performance_results: "Αποτελέσματα απόδοσης σε: ",
    performance_results_algorithm: "Αλγόριθμος",
    performance_results_lang: "Γλώσσα",
    performance_results_domain: "Τομέα",
    performance_results_status: "Κατάσταση",
    performance_results_intentF1Avg: "Intent F1 Μέση τιμή",
    performance_results_intentPrecisionAvg: "Intent Precision Μέση τιμή",
    performance_results_intentRecallAvg: "Intent Recall Μέση τιμή",
    performance_results_entitiesF1Avg: "Entities F1 Μέση τιμή",
    performance_results_entitiesPrecisionAvg: "Entities Precision Μέση τιμή",
    performance_results_entitiesRecallAvg: "Entities Recall Μέση τιμή",
    performance_results_intentF1AvgOld: "Παλιά Intent F1 Μέση τιμή",
    performance_results_intentPrecisionAvgOld:
        "Παλιά Intent Precision Μέση τιμή",
    performance_results_intentRecallAvgOld: "Παλιά Intent Recall Μέση τιμή",
    performance_results_entitiesF1AvgOld: "Παλιά Entities F1 Μέση τιμή",
    performance_results_entitiesPrecisionAvgOld:
        "Παλιά Entities Precision Μέση τιμή",
    performance_results_entitiesRecallAvgOld: "Παλιά Entities Recall Μέση τιμή",
    performance_results_cancel: "Ακυρώση",
    performance_results_discard: "Απορρίψη",
    performance_results_save: "Αποθηκεύση",
    performance_results_dicard_message:
        "Είστε βέβαιοι ότι θέλετε να απορρίψετε;",

    // retraining
    retraing_title: "Retrain",
    retraing_lan: "Γλώσσα",
    retraing_dom: "Domain",
    retraing_alg: "Αλγόριθμος",
    retraing_list_field_captions: ["#", "Όνομα", "Χρήση", "Αρχεία"],
    retraing_action_1: " Φόρτωση Λίστας Οντοτήτων",
    retraing_action_2: "Eκπαίδευσης Mοντέλοu",
    retraing_msg406: "Με βάση τα παραπάνω στοιχεία, βρέθηκε ήδη μια διαδικασία που είναι σε φάση επεξεργασίας. Πρέπει πρώτα να την αποθηκεύσετε ή να την απορρίψετε.",

    //statictis
    statistics_title: "Στατιστικά",
    statictis_enter_date: "Εισαγάγετε ένα εύρος ημερομηνιών",
    statictis_type: "Τύπος",
    statictis_results: "Αποτελέσματα",

    // =============  Enum ================
    enum_genders: [
        { id: "NA", name: "Μη διαθέσιμο" },
        { id: "MALE", name: "Άνδρας" },
        { id: "FEMALE", name: "Γυναίκα" },
    ],
    enum_salutations: ["Κος", "Κα", "Δις"],
    enum_rolesArray: [
        { id: "ADMIN", name: "Διαχειριστής" },
        { id: "DOMAIN_EXPERT", name: "Ειδικός domain" },
        { id: "USER", name: "Χρήστης" },
        { id: "ANONYMOUS", name: "Ανώνυμος" },
    ],
    enum_user_statusArray: ["ACTIVE", "DISABLED"],
    enum_MENU: [
        {
            id: 101,
            caption: "Home",
            visible: false,
            icon: "home",
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                //{ id: 21, caption: 'Κατάσταση μηχανήματος', visible: false, icon: 'home_repair_service', roles: ['ADMIN', 'DOMAIN_EXPERT', 'USER'] },
                //{ id: 22, caption: 'Τελευταία αναφορά', visible: false, icon: '', roles: ['ADMIN', 'DOMAIN_EXPERT', 'USER'] }
            ],
        },
        {
            id: 100,
            caption: "Chatbot",
            visible: false,
            icon: "question_answer",
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER", "ANONYMOUS"],
            sub: [
                // { id: 100, caption: 'Λίστα χρηστών', visible: false, icon: 'people_ald', roles: ['ADMIN'] },
            ],
        },
        {
            id: 230,
            caption: "NER & IE",
            visible: false,
            icon: "colorize",
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                // { id: 110, caption: 'Λίστα εταιριών', visible: false, icon: 'waves', roles: ['ADMIN'] }
            ],
        },
        {
            id: 210,
            caption: "Επισήμανση πρότασης",
            icon: "label",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                // { id: 120, caption: 'Λίστα πλοίων', visible: false, icon: 'directions_boat', roles: ['ADMIN'] }
            ],
        },
        {
            id: 220,
            caption: "Σύνολα δεδομένων",
            icon: "dns",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                //{ id: 130, caption: 'Λίστα συστημάτων', visible: false, icon: 'home_repair_service', roles: ['ADMIN'] }
            ],
        },
        {
            id: -1,
            caption: "Μοντέλο",
            icon: "batch_prediction",
            visible: false,
            roles: ["ADMIN"],
            sub: [
                {
                    id: 240,
                    caption: "Επανεκπαίδευση",
                    icon: "model_training",
                    visible: false,
                    roles: ["ADMIN"],
                },
                 {
                    id: 250,
                    caption: "Αποτελέσματα Απόδοσης",
                    visible: false,
                    icon: "app_registration",
                    roles: ["ADMIN"],
                },
            ],
        },
        {
            id: -1,
            caption: "Οργάνωση",
            icon: "settings_applications",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT"],
            sub: [
                {
                    id: 110,
                    caption: "Χρήστες",
                    visible: false,
                    icon: "display_settings",
                    roles: ["ADMIN"],
                },
                {
                    id: 120,
                    caption: "M.L. Algorithms",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN"],
                },
                {
                    id: 130,
                    caption: "Domains",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN", "DOMAIN_EXPERT"],
                },
                {
                    id: 150,
                    caption: "Οντότητες",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN"],
                },
                {
                    id: 160,
                    caption: "Προθέσεις",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN", "DOMAIN_EXPERT"],
                },
                {
                    id: 190,
                    caption: "Σχέσεις Προθέσεων",
                    icon: "display_settings",
                    visible: false,
                    roles: ["ADMIN", "DOMAIN_EXPERT"],
                },
            ],
        },
        {
            id: 280,
            caption: "Chatbot Config",
            icon: "live_help",
            visible: false,
            roles: ["ADMIN"],
            sub: [],
        },
        {
            id: 260,
            caption: "Προφίλ",
            icon: "person",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER"],
            sub: [],
        },
        {
            id: 270,
            caption: "Ιστορικό Χρήστη",
            icon: "dns",
            visible: false,
            roles: ["ADMIN"],
            sub: [],
        },
        {
            id: 290,
            caption: "Στατιστικά",
            icon: "show_chart",
            visible: false,
            roles: ["ADMIN"],
            sub: [],
        },
        {
            id: 200,
            caption: "Αποσύνδεση",
            icon: "logout",
            visible: false,
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER"],
            sub: [],
        },
        {
            id: 300,
            caption: "Περί",
            visible: false,
            icon: "info",
            roles: ["ADMIN", "DOMAIN_EXPERT", "USER", "ANONYMOUS"],
            sub: [],
        },
    ],
};

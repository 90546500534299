import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GlobalsService } from './globals.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from './auth/auth-service.service';
import { ClientService } from './client/client.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { VERSION } from '../environments/version';
import { FormControl } from '@angular/forms';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'bbb-client';
    isFrame = true;
    version = VERSION.version;
    isMenuVisible: boolean;
    isSpinnerVisible: boolean;
    fMenu: any;
    @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
    private unsub$ = new SubSink();
    ship = new FormControl('', []);
    ships = [];
    filteredShips: Observable<any[]>;
    company = new FormControl('', []);
    companies = [];
    filteredCompanies: Observable<any[]>;
    shipVisible = false;
    companyVisible = false;
    headerVisible = true;
    footerVisible = true;
    menuIsOpen = false;

    constructor(
        public globals: GlobalsService,
        private router: Router,
        private auth: AuthService,
        public client: ClientService,
        private dialog: MatDialog
    ) {
        this.globals.getMenuStatus().subscribe(
            x => {
                setTimeout(() => {
                    this.isMenuVisible = x;
                });
            }
        );
        this.globals.getSpinnerStatus().subscribe(
            x => {
                setTimeout(() => {
                    this.isSpinnerVisible = x;
                });
            }
        );
        this.client.calcMenu().subscribe(
            x => {
                setTimeout(() => {
                    this.fMenu = x;
                });
            }
        );
        this.globals.getIsFrameSub().subscribe(
            (x: boolean) => {
                setTimeout(() => {
                    this.isFrame = x;
                });
            }
        );

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // a useful property is event.url;
            }
            if (event instanceof NavigationEnd) {
                this.client.menuChanged();
                // this.headerVisible = event.url.indexOf('/login') < 0;
                // this.footerVisible = event.url.indexOf('/login') < 0;
            }
            if (event instanceof NavigationError) {
                console.log(event.error);
            }
        });

        window.addEventListener("message", ((event) => {
            // if (event.origin != 'http://javascript.info') {
            //   // something from an unknown domain, let's ignore it
            //   return;
            // }
            try {
                var msg = event.data;
                if (msg.lang) {
                    //alert("received: " + JSON.stringify(event.data));
                    this.globals.currentLang = msg.lang;
                    // can message back using event.source.postMessage(...)
                }
            } catch (error) {
                console.log('invalid message');
            }

        })
        )
    }


    ngOnInit(): void {
        const x = this.globals.currentLang;
        this.client.resetUser();
        this.globals.currentLang = x; // force set language
        if (this.client.token) {
            this.globals.showBtnLoginRegister = false;
        }
        else {
            this.globals.showBtnLoginRegister = true;
        }
    }

    ngOnDestroy() {
        this.unsub$.unsubscribe();
    }
    login() {
        this.router.navigate(['/admin/login']);
        this.menuIsOpen = false;
    }
    register() {
        this.router.navigate(['/register/-1']);
        this.menuIsOpen = false;
    }
    doMenu(idx: any) {
        switch (idx) {
            case 100: {
                this.router.navigate(['chat']);
                break;
            }
            case 101: {
                this.router.navigate(['home']);
                break;
            }
            case 110: {
                this.router.navigate(['admin/userlist']);
                break;
            }
            case 120: {
                this.router.navigate(['algorithm/list']);
                break;
            }
            case 130: {
                this.router.navigate(['domain/list']);
                break;
            }
            case 150: {
                this.router.navigate(['entity/list']);
                break;
            }
            case 160: {
                this.router.navigate(['intent/list']);
                break;
            }
            case 190: {
                this.router.navigate(['intent/relation']);
                break;
            }
            case 210: {
                this.router.navigate(['sentence']);
                break;
            }
            case 220: {
                this.router.navigate(['dataset/list']);
                break;
            }
            case 230: {
                this.router.navigate(['ner-ie/list']);
                break;
            }
            case 240: {
                this.router.navigate(['retrain']);
                break;
            }
            case 250: {
                this.router.navigate(['performance']);
                break;
            }
            case 260: {
                this.router.navigate(['admin/profile']);
                break;
            }
            case 270: {
                this.router.navigate(["user-history/list"]);
                break;
            }
            case 280: {
                this.router.navigate(["chat-config"]);
                break;
            }
            case 290: {
                this.router.navigate(["statistics"]);
                break;
            }
            case 200: {
                this.globals.showDialog(
                    this.globals.loc.gl_warning,
                    this.globals.loc.logout_confirm,
                    [
                        { text: this.globals.loc.gl_no, result: 0 },
                        { text: this.globals.loc.gl_yes, result: 1 },
                    ],
                    (res) => {
                        if (res === 1) {
                            this.client.doLogout();
                            this.router.navigate(["home"]);
                        } else {
                            setTimeout(() => {
                                this.trigger.closeMenu();
                            }, 100);
                        }
                    }
                );
                break;
            }
        }
        this.menuIsOpen = false;
    }
    toggleMenu() {
        this.menuIsOpen = !this.menuIsOpen;
    }
}

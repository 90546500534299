import {
    Component,
    OnInit,
    OnDestroy,
    Injector,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { BaseComponent } from "src/app/base/base/base.component";
import { Location as LocationService } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalsService } from "src/app/globals.service";
import { AuthService } from "src/app/auth/auth-service.service";
import { ClientService } from "src/app/client/client.service";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { Ichat } from "src/app/helper-classes/helper";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { map, tap } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";

export const EnumAnswerType = { INPUT: 0, PREDEFINED: 1 };
export const EnumBotState = {
    COMPLETED: 0,
    REQUIRES_BASIC_INFO: 1,
    REQUIRES_MORE_INFO: 2,
};

@Component({
    selector: "app-chat",
    templateUrl: "./chat.component.html",
    styleUrls: ["./chat.component.scss"],
})
export class ChatComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild("chatMid", { static: true }) chatMid: ElementRef<any>;
    private inputBox;
    chatObserv: BehaviorSubject<string | null | any> = new BehaviorSubject(
        null
    );
    chatArray: Array<Ichat> = [];
    algorithm = new FormControl({ value: null, disabled: false }, []);
    domain = new FormControl({ value: null, disabled: false }, []);
    question = new FormControl({ value: null, disabled: false }, [
        Validators.required,
    ]);
    msForm = new FormGroup({
        question: this.question,
    });
    rightIsOpen = false;
    algorithmArray = [];
    domainArray = [];
    readonly enumAnswerType: typeof EnumAnswerType = EnumAnswerType;
    answerType = EnumAnswerType.INPUT;
    predefinedAnswers = [];
    lastResponse = null;
    lockFooter = false;
    showResults = false;
    resultsArray = [];
    private _intentQuestions: any;
    private _entityQuestions: any;
    algorithmId: any;
    domainId: any;

    constructor(
        protected injector: Injector,
        protected routeParams: ActivatedRoute,
        public globals: GlobalsService,
        public auth: AuthService,
        public client: ClientService,
        protected router: Router,
        private sanitizer: DomSanitizer
    ) {
        super(injector, routeParams, globals, auth, client, router);
        this.chatObserv.asObservable().subscribe((e) => {
            setTimeout(() => {
                let id = 1;
                let indexOfClear = 0;
                if (this.chatArray.length > 10) {
                    indexOfClear = this.chatArray.length - 10;
                }
                for (
                    let index = this.chatArray.length;
                    index > indexOfClear;
                    index--
                ) {
                    if (this.chatArray[index - 1].sender == "typing-dots") {
                        this.chatArray.splice(index - 1, 1);
                    }
                }
                if (this.chatArray.length) {
                    id = this.chatArray[this.chatArray.length - 1].id;
                }
                if (e) {
                    if (typeof e == "string") {
                        // const split = e.split(' '); // split words
                        // for (let i = 0; i < split.length; i++) {
                        //     switch (split[i]) {
                        //         case ';-)':
                        //             split[i] = '::wink'
                        //             break;
                        //         case ':-(':
                        //             split[i] = '::disappointed'
                        //             break;
                        //         case '::lol':
                        //             split[i] = '::rolling_on_the_floor_laughing'
                        //             break;
                        //         case '::think':
                        //             split[i] = '::thinking_face'
                        //             break;
                        //         default:
                        //             break;
                        //     };
                        // }
                        this.chatArray.push({
                            icon: null,
                            id: Number(id) + 1,
                            sender: "bot",
                            text: e,
                            button: null,
                            rate: null,
                        });
                    } else if (e.text && e.button) {
                        const split = e.text;
                        this.chatArray.push({
                            icon: null,
                            id: Number(id) + 1,
                            sender: "bot",
                            text: split,
                            button: {
                                caption: e.button.caption,
                                data: e.button.data,
                            },
                            rate: null,
                        });
                    } else if (e.text && e.rate != null) {
                        const split = e.text;
                        this.chatArray.push({
                            icon: null,
                            id: Number(id) + 1,
                            sender: "bot",
                            text: split,
                            button: null,
                            rate: e.rate,
                        });
                    } else {
                        this.chatArray.push({
                            icon: null,
                            id: Number(id) + 1,
                            sender: "bot",
                            text: this.sanitizer.bypassSecurityTrustUrl(e),
                            button: null,
                            rate: null,
                        });
                    }
                    setTimeout(() => {
                        this.chatMid.nativeElement.scrollTop =
                            this.chatMid.nativeElement.scrollHeight;
                    }, 100);
                }
            }, 100);
        });
        this.globals.setIsFrame(false);
    }

    public get intentQuestions(): any {
        return this._intentQuestions;
    }
    public set intentQuestions(v: any) {
        this._intentQuestions = v;
        if (v) {
            this._intentQuestions.outerQidx = 0;
            this._intentQuestions.isInner = false;
        }
    }

    public get entityQuestions(): any {
        return this._entityQuestions;
    }
    public set entityQuestions(v: any) {
        this._entityQuestions = v;
        if (v) {
            this._entityQuestions.qIdx = 0;
        }
    }
    protected onLangChange(): void {
        super.onLangChange();
        let lng =
            this.client.currentUser && this.client.currentUser.profile
                ? this.client.currentUser.profile.language
                : this.globals.currentLang;
        this.chatArray[0] = {
            icon: null,
            id: Number(0) + 1,
            sender: "bot",
            text: this.globals.loc.menu_bot_text2,
            button: null,
            rate: null,
        };
    }

    showDetails(item: any) {
        this.showResults = true;
        this.resultsArray = [];
        item.button.data.forEach((e) => {
            this.resultsArray.push({
                name: e.name,
                link: this.sanitizer.bypassSecurityTrustUrl(
                    "https://www.tripadvisor.com" + e.href
                ),
            });
        });
        item.button.data;
        //this.globals.showDialog(item.text, JSON.stringify(item.button.data));
    }
    exitDialog() {
        this.showResults = false;
        this.resultsArray = [];
    }

    doQuestion(evt, fromClick: boolean, secondaryData) {
        if (
            (((evt && evt.code == "Enter") || fromClick) &&
                this.question.value) ||
            secondaryData
        ) {
            const q = this.question.value;
            this.question.setValue(null);
            this.question.updateValueAndValidity();
            let id = 1;
            if (this.chatArray.length) {
                id = this.chatArray[this.chatArray.length - 1].id;
            }
            if (q)
                this.chatArray.push({
                    icon: null,
                    id: Number(id) + 1,
                    sender: "user",
                    text: q,
                    button: null,
                    rate: null,
                });
            const lastChatItem = this.chatArray[this.chatArray.length - 1];
            if (lastChatItem.sender != "typing-dots")
                this.chatArray.push({
                    icon: null,
                    id: Number(id) + 1,
                    sender: "typing-dots",
                    text: null,
                    button: null,
                    rate: null,
                });
            setTimeout(() => {
                this.chatMid.nativeElement.scrollTop =
                    this.chatMid.nativeElement.scrollHeight;
            }, 100);
            const domainObj = this.domainArray.find(
                (e) => this.domain.value == e.id
            );
            const algorithmObj = this.algorithmArray.find(
                (e) => this.algorithm.value == e.id
            );
            let body: any = {
                lang: this.globals.currentLang,
                input: q,
                algorithmId: algorithmObj
                    ? algorithmObj.customId
                    : this.algorithmId,
                domainId: domainObj ? domainObj.customId : this.domainId,
            };
            if (this.lastResponse) {
                body.sessionId = this.lastResponse.sessionId;
                body.lastStatus = this.lastResponse.status;
                if (
                    EnumBotState[this.lastResponse.status] ==
                        EnumBotState.REQUIRES_BASIC_INFO &&
                    secondaryData
                ) {
                    body.intentQuestions = secondaryData.intentQuestions;
                } else if (
                    EnumBotState[this.lastResponse.status] ==
                    EnumBotState.REQUIRES_MORE_INFO
                ) {
                    this.entityQuestions[this.entityQuestions.qIdx].answer = q;
                    setTimeout(() => {
                        this.inputBox = document.getElementById("inputBox");
                        if (this.inputBox) this.inputBox.focus();
                    }, 100);
                    if (
                        this.entityQuestions.qIdx <
                        this.entityQuestions.length - 1
                    ) {
                        this.entityQuestions.qIdx++;
                        this.doDialog(EnumBotState[this.lastResponse.status]);
                        return;
                    } else {
                        // end of questions
                        delete body.input;
                        body.questions = this.entityQuestions;
                    }
                } else if (q) body.input = q;
            } else if (q) body.input = q;
            else return;
            this.answerType = EnumAnswerType.INPUT;
            this.predefinedAnswers = [];
            this.lockFooter = true;
            this.question.disable();
            this.subsink.sink = this.client
                .doChat(body)
                .pipe(
                    tap((_) => {
                        this.lockFooter = false;
                        this.question.enable();
                        setTimeout(() => {
                            this.inputBox = document.getElementById("inputBox");
                            if (this.inputBox) this.inputBox.focus();
                        }, 100);
                    }),
                    map((resp) => {
                        // temp code for debuging the Intent Label
                        // let q = resp.intentQuestions || [];
                        // q.forEach(e => {
                        //     let a = e.answers || [];
                        //     a.forEach(an => {
                        //         an.label = an.label + ' lab';
                        //         if (an.dependentQuestion) {
                        //             let a1 = an.dependentQuestion.answers || [];
                        //             a1.forEach(an1 => {
                        //                 an1.label = an1.label + ' lab';
                        //             });
                        //         };
                        //     });
                        // });
                        //
                        return resp;
                    })
                )
                .subscribe((res) => {
                    if (res && !res.errorCode) {
                        const status = EnumBotState[res.status];
                        if (res.lang) {
                            this.globals.currentLang = res.lang;
                        }
                        if (status == undefined) {
                            this.chatObserv.next(
                                res.lang == "EN"
                                    ? `Sorry I didn’t understand. Τype something like “Suggest a hotel in athens”`
                                    : 'Συγνώμη δεν κατάλαβα. Πληκτρολογήστε κάτι σαν "Πρότεινε ξενοδοχείο στην Αθήνα"'
                            );
                        } else if (status == EnumBotState.COMPLETED) {
                            this.lastResponse = res;
                            this.intentQuestions = null;
                            this.entityQuestions = null;
                            this.handleCompleted(res);
                        } else if (status == EnumBotState.REQUIRES_BASIC_INFO) {
                            this.lastResponse = res;
                            this.entityQuestions = null;
                            this.intentQuestions = res.intentQuestions.filter(
                                (ee) => ee.lang == res.lang
                            );
                            this.doDialog(status);
                        } else if (status == EnumBotState.REQUIRES_MORE_INFO) {
                            this.lastResponse = res;
                            this.entityQuestions = res.questions;
                            this.intentQuestions = null;
                            this.doDialog(status);
                        } else {
                            this.chatObserv.next(res.output);
                        }
                    } else {
                        this.handleError(
                            "Error: Possible reason: Params algorithm, domain missing..."
                        );
                    }
                });
        }
    }
    handleCompleted(res: any) {
        let build: any = {};
        if (res.results) {
            build.results = [];
            for (let i = 0; i < res.results.length; i++) {
                const r = res.results[i];
                build.results.push({
                    name: r.name,
                    href: r.href,
                    description: r.description || "",
                });
            }
        }
        if (res.recommendations) {
            build.recommendations = [];
            for (let i = 0; i < res.recommendations.length; i++) {
                const r = res.recommendations[i];
                build.recommendations.push({
                    name: r.name,
                    href: r.href,
                    description: r.description || "",
                });
            }
        }
        // nothing useful found
        if (
            (!build.results || !build.results.length) &&
            (!build.recommendations || !build.recommendations.length) &&
            !res.output
        ) {
            if (res.criteria && res.criteria.length) {
                if (this.lastResponse.lang == "EN") {
                    this.chatObserv.next(
                        `No results found based on these criteria: ${res.criteria.join(
                            ", "
                        )}`
                    );
                } else {
                    this.chatObserv.next(
                        `Δεν βρέθηκαν αποτελέσματα με βάση αυτά τα κριτήρια: ${res.criteria.join(
                            ", "
                        )}`
                    );
                }
            } else {
                if (this.lastResponse.lang == "EN") {
                    this.chatObserv.next(`No results found`);
                } else {
                    this.chatObserv.next(`Δεν βρέθηκαν αποτελέσματα`);
                }
            }
        } else {
            // something found
            // display only for results and recommendations NOT output
            if (
                (build.results && build.results.length) ||
                (build.recommendations && build.recommendations.length)
            ) {
                if (res.criteria && res.criteria.length) {
                    if (this.lastResponse.lang == "EN") {
                        this.chatObserv.next(
                            `I found the following results based on these criteria: ${res.criteria.join(
                                ", "
                            )}`
                        );
                    } else {
                        this.chatObserv.next(
                            `Βρήκα τα ακόλουθα αποτελέσματα με βάση αυτά τα κριτήρια: ${res.criteria.join(
                                ", "
                            )}`
                        );
                    }
                } else {
                    if (this.lastResponse.lang == "EN") {
                        this.chatObserv.next(`I found the following results:`);
                    } else {
                        this.chatObserv.next(`Βρήκα τα ακόλουθα αποτελέσματα:`);
                    }
                }
            }
        }
        // if results
        if (build.results && build.results.length) {
            this.chatObserv.next({
                text: this.globals.loc.results_caption,
                button: {
                    caption: this.globals.loc.gl_show,
                    data: build.results || [],
                },
            });
        }

        // if output
        if (res.output) {
            this.chatObserv.next(res.output);
        }
        // rate
        this.chatObserv.next({ text: this.globals.loc.rate_prompt, rate: 0 });
    }

    private doDialog(status) {
        try {
            if (status == EnumBotState.REQUIRES_BASIC_INFO) {
                const q = this.intentQuestions[this.intentQuestions.outerQidx];
                this.answerType = EnumAnswerType.PREDEFINED;
                if (!q.answer) {
                    this.predefinedAnswers = q.answers;
                    this.chatObserv.next(q.question);
                } else {
                    const _ans = (q.answers ?? []).find(
                        (e) => e.text == q.answer
                    );
                    setTimeout(() => {
                        this.doAnswer(_ans, true);
                    }, 100);
                }
            } else if (status == EnumBotState.REQUIRES_MORE_INFO) {
                const q = this.entityQuestions[this.entityQuestions.qIdx];
                this.chatObserv.next(
                    this.lastResponse.lang == "EN" ? q.questionEn : q.questionGr
                );
                this.answerType = EnumAnswerType.INPUT;
                this.predefinedAnswers = [];
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    doAnswer(ans, hide: boolean = false) {
        ans.selected = true;
        //this.chatObserv.next({ icon: null, id: Number(this.chatArray[this.chatArray.length - 1].id) + 1, sender: 'user', text: ans.text, button: null });
        //this.chatObserv.next({ text: this.globals.loc.recommendation_caption, button: { caption: this.globals.loc.gl_show, data: build.recommendations } });
        if (!hide)
            this.chatArray.push({
                icon: null,
                id: Number(this.chatArray[this.chatArray.length - 1].id) + 1,
                sender: "user",
                text: ans.label,
                button: null,
                rate: null,
            });
        if (this.intentQuestions.isInner) {
            this.intentQuestions.isInner = false;
            if (
                this.intentQuestions.outerQidx <
                this.intentQuestions.length - 1
            ) {
                this.intentQuestions.outerQidx++;
                this.doDialog(this.lastResponse.status);
            } else {
                this.completeDialog();
            }
        } else if (ans.dependentQuestion) {
            const q = ans.dependentQuestion;
            this.answerType = EnumAnswerType.PREDEFINED;
            this.intentQuestions.isInner = true;
            if (!q.answer) {
                this.predefinedAnswers = q.answers;
                this.chatObserv.next(q.question);
            } else {
                const _ans = (q.answers ?? []).find((e) => e.text == q.answer);
                if (!_ans) {
                    this.predefinedAnswers = q.answers;
                    this.chatObserv.next(q.question);
                } else {
                    setTimeout(() => {
                        this.doAnswer(_ans, true);
                    }, 100);
                }
            }
        } else {
            this.completeDialog();
        }
    }

    completeDialog() {
        this.answerType = EnumAnswerType.INPUT;
        this.predefinedAnswers = [];
        if (this.intentQuestions) {
            const body = { intentQuestions: [] };
            for (let i = 0; i < this.intentQuestions.length; i++) {
                const iq = this.intentQuestions[i];
                const answeredQ = iq.answers.find((e) => e.selected);
                const obj = {
                    propertyName: iq.propertyName,
                    answer: answeredQ.text,
                    id: answeredQ.id,
                };
                body.intentQuestions.push(obj);
                if (answeredQ.dependentQuestion) {
                    const answeredQ2 = answeredQ.dependentQuestion.answers.find(
                        (e) => e.selected
                    );
                    const obj2 = {
                        propertyName: answeredQ.dependentQuestion.propertyName,
                        answer: answeredQ2.text,
                        id: answeredQ2.id,
                    };
                    body.intentQuestions.push(obj2);
                }
            }
            this.doQuestion(null, false, body);
            this.intentQuestions = null;
        }
    }

    protected loadData(): void {
        super.loadData();
        this.client.fixMenu();
        // temporarily set fixed values
        //this.algorithm.setValue(1);
        //this.domain.setValue(1);
        this.domain.disable();
        //
        // this.chatObserv.next("For enquiries and further information, please leave your details <a href='https://plazz.com/en-US/ContactUs' target='_blank''>here</a> and we will get back to you.");
        // this.chatObserv.next('Please, remember that, since I am a demo, I only can repeat your question... ::lol');
        if (this.client.currentUser.role == "ADMIN") {
            // in this case fetch data for lookups
            const $algorithm = this.client.getAlgorithm();
            const $domain = this.client.getDomainList();
            this.subsink.sink = forkJoin([
                $algorithm,
                $domain,
            ]).subscribe((res) => {
                this.algorithmArray = res[0];
                this.domainArray = res[1];
            });
        }
        this.client.getChatDefault().subscribe((res) => {
            this.algorithmId = res.algorithmId;
            this.domainId = res.domainId;
        });
        this.afterLoadData();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.chatObserv.unsubscribe();
    }

    toggleRight() {
        this.rightIsOpen = !this.rightIsOpen;
    }
    algorithmChanged(evt) {
        this.domain.enable();
    }
    
    handleError(ex) {
        this.lastResponse = "COMPLETED";
        this.intentQuestions = null;
        this.entityQuestions = null;
        if (ex) console.log(ex);
        this.chatObserv.next(
            this.globals.currentLang == "EN"
                ? "Sorry, an error occured"
                : "Συγνώμη, προκλήθηκε κάποιο λάθος"
        );
    }

    applyRate(rate: number) {
        this.client.rating(rate, this.lastResponse.sessionId).subscribe((e) => {
            this.chatObserv.next(this.globals.loc.rate_thanks);
        });
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpRequest, HttpEventType, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Subscription, of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { GlobalsService } from '../../globals.service';
import { ClientService } from '../../client/client.service';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
      animations: [
            trigger('fadeInOut', [
                  state('in', style({ opacity: 100 })),
                  transition('* => void', [
                        animate(300, style({ opacity: 0 }))
                  ])
            ])
      ]
})
export class FileuploadComponent implements OnInit {

      /** Link text */
      @Input() text = 'Upload';
      /** Name used in form which will be sent in HTTP request. */
      @Input() param = 'file';
      @Input() extraParams = {};
      /** Target URL for file uploading. */
      @Input() target = 'https://file.io';
      /** File extension that accepted, same as 'accept' of <input type="file" />  By default, it's set to 'image/*'. */
      @Input() accept = 'application/json'; // 'image ,  pdf/*';
      /** Allow you to add handler after its completion. Bubble up response text from remote. */
      @Input() entityId = '';
      @Input() btnDisabled = false;
      // tslint:disable-next-line: no-output-native
      @Output() complete = new EventEmitter<any>();

      files: Array<FileUploadModel> = [];

      constructor(
            private _http: HttpClient,
            private globals: GlobalsService,
            private client: ClientService) { }

      ngOnInit() {
            const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
            fileUpload.accept = this.accept;
      }

      onClick() {
            const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
            fileUpload.accept = this.accept;
            fileUpload.onchange = () => {
                  // tslint:disable-next-line: prefer-for-of
                  for (let index = 0; index < fileUpload.files.length; index++) {
                        const file = fileUpload.files[index];
                        this.files.push({ data: file, state: 'in', fileName: file.name,
                          inProgress: false, progress: 0, canRetry: false, canCancel: true });
                  }
                  this.uploadFiles();
            };
            fileUpload.click();
      }

      cancelFile(file: FileUploadModel) {
            file.sub.unsubscribe();
            this.removeFileFromArray(file);
      }

      retryFile(file: FileUploadModel) {
            this.uploadFile(file);
            file.canRetry = false;
      }

      private uploadFile(file: FileUploadModel) {
            const fd = new FormData();
            fd.append(this.param, file.data);
            fd.append('newName', file.fileName);
            // tslint:disable-next-line: forin
            for (const i in this.extraParams) {
                  fd.append(i, this.extraParams[i]);
            }
            const header = {
                  // 'Content-Type': 'application/json',
                  Authorization : this.client.getHeaderAuth()
              };
            const req = new HttpRequest('POST', this.target, fd, {
                  reportProgress: true,
                  headers: new HttpHeaders(header),
                  // withCredentials: true
            });

            file.inProgress = true;
            file.sub = this._http.request(req).pipe(
                  map(event => {
                        switch (event.type) {
                              case HttpEventType.UploadProgress:
                                    file.progress = Math.round(event.loaded * 100 / event.total);
                                    break;
                              case HttpEventType.Response:
                                    return event;
                        }
                  }),
                  tap(message => {
                        // log here
                  }),
                  last(),
                  catchError((error: HttpErrorResponse) => {
                        file.inProgress = false;
                        file.canRetry = true;
                        return of({status: error.status, message: error.message + ' ' + error.error.reason});
                  })
            ).subscribe(
                  (event: any) => {
                        if (typeof (event) === 'object') {
                              this.removeFileFromArray(file);
                              if (event.status == 200) {
                                    this.complete.emit({file: file.data, success: true, id: this.entityId, message: `${file.data.name} upload succeeded.`});
                              } else {
                                    this.complete.emit({
                                          file: file.data, success: false,
                                          id: this.entityId, message: `${file.data.name} upload failed. "${event.message}"`
                                    });
                              }
                        } else {
                              this.complete.emit({file: file.data, success: false, id: this.entityId, message: event});
                        }
                  }
            );
      }

      private uploadFiles() {
            const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
            fileUpload.value = '';

            this.files.forEach(file => {
                  this.uploadFile(file);
            });
      }

      private removeFileFromArray(file: FileUploadModel) {
            const index = this.files.indexOf(file);
            if (index > -1) {
                  this.files.splice(index, 1);
            }
      }
}

export class FileUploadModel {
      data: File;
      fileName: string;
      state: string;
      inProgress: boolean;
      progress: number;
      canRetry: boolean;
      canCancel: boolean;
      sub?: Subscription;
}
